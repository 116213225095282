import React from "react"
import { TCLabel } from "../Label/TopCourtLabel"
import { Grid, Icon } from "@mui/material"
import { color } from "helper/constant"

function OrderInformation ({
    takeScreenshot,
    p=2,
    mt=-1
}) {
    return (
        <Grid p={takeScreenshot ? 2 : p} pt={0} mt={mt}>
            {
                !takeScreenshot &&
                <Grid>
                    <TCLabel subtitle><span><Icon>check</Icon></span> No Refund & No Reschedule.</TCLabel>
                    <TCLabel subtitle><span><Icon>check</Icon></span> Use tennis court at your own risk.</TCLabel>
                    <TCLabel subtitle><span><Icon>check</Icon></span> Prepare Check-Out 5 mins prior to session changeover.</TCLabel>
                    <TCLabel subtitle><span><Icon>check</Icon></span> By completing payment, you agree to STC <span style={{cursor: 'pointer', textDecoration: 'underline'}}>Terms & Conditions</span> and <span style={{cursor: 'pointer', textDecoration: 'underline'}}>Court Rules</span>.</TCLabel>
                </Grid>
            }
            {
                takeScreenshot &&
                <Grid>
                    <TCLabel style={{fontSize: 8}}><span><Icon>check</Icon></span> No Refund & No Reschedule.</TCLabel>
                    <TCLabel style={{fontSize: 8}}><span><Icon>check</Icon></span> Use tennis court at your own risk.</TCLabel>
                    <TCLabel style={{fontSize: 8}}><span><Icon>check</Icon></span> Prepare Check-Out 5 mins prior to session changeover.</TCLabel>
                    <TCLabel style={{fontSize: 8}}><span><Icon>check</Icon></span> By completing payment, you agree to STC <span style={{cursor: 'pointer', textDecoration: 'underline'}}>Terms & Conditions</span> and <span style={{cursor: 'pointer', textDecoration: 'underline'}}>Court Rules</span>.</TCLabel>
                </Grid>
            }
        </Grid>
    )
}

export default OrderInformation