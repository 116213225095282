import React, { useRef, useEffect, useState } from "react";
// import ReactDOM from "react-dom";
import SlideAlertAction from "components/SlideInAlert/SlideInAlertAction";
import { createRoot } from "react-dom/client";

const rootID = "alert-dialog";
let alertContainer = null;
// our promise to be stored for resolve on input
let returnResponse;

const AlertRoot = (props) => {
    // include type
	const { title, message, type, show, okActionTitle = "OK", cancelActionTitle = "Cancel" } = props;
    const [ open, setOpen ] = useState(show);
    const { cancelAction = () => {}, okAction = () => {} } = props;
	const root = useRef();

	useEffect(() => {
		let div = document.getElementById(rootID);
		root.current = div;
	}, [])

	function Close() {
        setOpen(false)
		root.current?.remove();
	}

	return (
		<SlideAlertAction 
            isOpen={open} 
            title={title}
            setIsOpen={() => { Close() }}
            subTitle={message}
            cancelAction={() => {
                cancelAction()
                Close()
            }}
            okAction={() => {
                okAction()
                Close()
            }}
            okActionTitle={okActionTitle}
            cancelActionTitle={cancelActionTitle}
            maxWidth={"sm"}
            type={type}
        />
	);
}

// pass in alert type
function Create(title, message, type, cancelAction, okAction) {
	let div = document.getElementById(rootID);
	if (!div) {
		div = document.createElement("div");
		div.id = rootID;
		document.body.appendChild(div);
        alertContainer = createRoot(div);
	}
    alertContainer.render(
        <AlertRoot
            show={true}
            message={message}
            title={title}
            type={type}
            cancelAction={cancelAction}
            okAction={okAction}
        />
    );
}

function CreateCustomTitle(title, message, type, cancelAction, okAction, cancelTitle, okTitle ) {
	let div = document.getElementById(rootID);
	if (!div) {
		div = document.createElement("div");
		div.id = rootID;
		document.body.appendChild(div);
        alertContainer = createRoot(div);
	}
    alertContainer.render(
        <AlertRoot
            show={true}
            message={message}
            title={title}
            type={type}
            cancelAction={cancelAction}
            okAction={okAction}
            cancelActionTitle={cancelTitle}
            okActionTitle={okTitle}
        />
    );
}

// new confirm method
export function Confirm(title, message) {
    // pass in type
	Create(title, message, 'confirm');
}

// new confirm method
export function Action(title, message, okAction) {
    // pass in type
	Create(title, message, 'confirm', null, okAction);
}

// new confirm method
export function ConfirmationOneButton(title, message, okTitle, okAction) {
    // pass in type
	CreateCustomTitle(title, message, 'confirm', () => {}, okAction, "No", okTitle);
}

// new confirm method
export function Confirmation(title, message, cancelAction, okAction) {
    // pass in type
    console.log(title, message, cancelAction, okAction)
	Create(title, message, 'action', cancelAction, okAction);
}

export function ConfirmationYesNo(title, message, cancelAction, okAction) {
    // pass in type
    console.log(title, message, cancelAction, okAction)
	CreateCustomTitle(title, message, 'action', cancelAction, okAction, "No", "Yes");
}

export function Alert(title, message) {
	// pass in type
    Create(title, message, 'alert');
}