import React, { useEffect, useRef, useState } from "react";
import { getCourtsSchedule } from "pages/Booking/Function";
import { Hours, color, isPhone, courtType, centerBox } from 'helper/constant';
import { getCourtInfo } from "helper/localStorage";

import { Box, Container, Grid, Icon } from "@mui/material";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import MKTypography from "components/MKTypography";
import { toGMT7 } from "helper/time";
import { getCourtsDetail } from "pages/Booking/Function";
import { toLocalGMT } from "helper/time";
import MKButton from "components/MKButton";
import { TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";

import QRLogo from "assets/logo/payment/QRIS_logo.png"
import SummaryComponent from "./function/view/Summary";
import CounterRentorBuy from "./function/view/CounterRentorBuy";
import { setCartInfo } from "./function/helper/localStorage";
import { bubbleSort } from "helper/sort";
import { Confirm } from "helper/showAlert";
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput";
import { checkQRService } from "service/counterService";
import { playSound } from "./function/helper/playSound";
import { useNavigate } from "react-router-dom";
import { addCart, paymentLogicReturnData, deleteCart } from "pages/Booking/Function";
import { getUserCart } from "pages/Booking/Function";
import { getUserInfo } from "helper/localStorage";
import moment from "moment";
import { addMonths } from "date-fns";
import CounterPayment from "./CounterPayment";
import { currencyFormat } from "helper/numberFormat";
import { deleteBookingService } from "service/counterService";
// check if counter being set ( login counter )

let port = null

function CounterBooking ({
    
}) {

    const courtInfoJSON = getCourtInfo ? JSON.parse(getCourtInfo) : null
    const user = getUserInfo ? JSON.parse(getUserInfo) : null

    const playSoundRef = useRef(null)
    const navigate = useNavigate()

    const [ courtInfo, setCourtInfo ] = useState(courtInfoJSON)
    const [ courts, setCourts ] = useState([])
    const [ counterInfo, setCounterInfo ] = useState(null)
    const [ booking, setBooking ] = useState([])

    const [selectedDefaultDate, setSelectedDefaultDate ] = useState(new Date())
    const [ selection, setSelection ] = useState("booking")

    const [ form, setForm ] = useState({
        selectedDate: toLocalGMT(new Date()),
        date: toGMT7(new Date()),
        month: toGMT7(new Date(), 'MM'),
        year: toGMT7(new Date(), 'YYYY'),
        court: null,
        hours: [],
        totalSessions: 0,
        totalPrice: 0,
    })

    const [ availableHours, setAvailableHours ] = useState(Hours)
    const [ loading, setLoading ] = useState(false)

    const [ carts, setCarts ] = useState(null)

    const [ rawBookings, setRawBookings ] = useState([])
    const [ rents, setRents ] = useState([])
    const [ products, setProducts ] = useState([])

    const [ rerender, setRerender ] = useState(Date.now())
    const [ qrCodeValue, setQRCodeValue ] = useState("")

    const [ currentPort, setCurrentPort ] = useState(null)
    const [ paymentId, setPaymentId ] = useState(null)

    const [ firstAccess, setFirstAccess ] = useState(true)

    useEffect(() => {
        if (firstAccess) {
            console.log('here user info', user)
            deleteBookingService(user._id)
            setFirstAccess(false)
        }
    }, [firstAccess])

    useEffect(() => {
        if (courtInfo && courtInfo._id) { loadCourts() }
    }, [courtInfo])

    useEffect(() => { console.log('here bookingData', rerender) }, [rerender])

    useEffect(() => {
        getCourtsSchedule(form.court, form.date, setAvailableHours, setLoading);
    }, [])

    useEffect(() => {
        if (form.court) { 
            setLoading(true)
            getCourtsSchedule(form.court, form.date, setAvailableHours, setLoading);
            getUserCart(setBooking, setLoading)
            setRerender(Date.now())
        }
    }, [form.date, form.court])

    useEffect(() => {
    }, [availableHours])

    useEffect(() => {
        let bookingHours = checkBookingHours(new Date(form.date))
        onChange({
            ...bookingHours
        });
    }, [booking])

    useEffect(() => {
        console.log('here check payment id', paymentId)
    }, [paymentId])

    const loadCourts = async () => {
        try {
            getCourtsDetail(courtInfo, setCourts, setForm)
        } catch (error) {
            console.log(error)
        }
    }

    const onChange = (e) => {
        setForm(prev => ({
            ...prev,
            ...e
        }))
    }

    const checkBookingHours = (date, court) => {
        let dateString = toGMT7(date, 'YYYY-MM-DD')
        court = form.court
        let bookingInfo = {
            hours: [], 
            totalSessions: 0,
            totalPrice: 0
        }
        for (let i = 0; i < booking.length; i++) {
            let courtId = (booking[i].court && booking[i].court._id) ? booking[i].court._id.toString() : booking[i].court.toString()
            if (booking[i].date == dateString && courtId == court.toString()) {
                // hours = booking[i].hours;
                bookingInfo.hours = booking[i].hours
                bookingInfo.totalSessions = booking[i].totalSessions
                bookingInfo.totalPrice = booking[i].totalPrice
                break;
            }
        }
        return bookingInfo
    }

    const addBooking = () => {
        console.log(form)
        addCart(form, setBooking, setLoading)
    }

    const onDeleteBookings = (e) => {
        deleteCart(e._id, e, setBooking, setLoading)
    }
    
    const calculateSummary = () => {
        let totalSession = 0;
        let totalPrice = 0;
        booking.map( value => {
            if ((value.hours && value.hours.length > 0) || value.totalSessions > 0) {
                totalSession += value.totalSessions
                totalPrice += value.totalPrice
            }
        })
        return { totalSession, totalPrice }
    }

    const payment = async () => {
        let data = await paymentLogicReturnData(form, () => {} , () => {}, '', false, setLoading)
        if (data) {
            setPaymentId(data._id)
        }
    }

    return(
        <Grid container style={{
            backgroundColor: 'white',
            borderRadius: 10
        }} onClick={(e) => {
            e.stopPropagation()
        }}>
            {
                !paymentId &&
                <Grid container xs={12} p={2} spacing={2}>
                    {
                        selection == "booking" &&
                        <Grid item container xs={6} height={"65vh"}>
                            <Grid item>
                                <TCLabel>{moment(new Date()).format("DD/MM/YYYY")}</TCLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <CourtComponent courtInfo={courtInfo} form={form} courts={courts} onChange={onChange} checkBookingHours={checkBookingHours}/>
                            </Grid>
                            <Grid item xs={12} sx={{height: '100vh'}}>
                                <HoursComponent availableHours={availableHours} onChange={onChange} form={form} 
                                    rerender={rerender}
                                    isNotActiveCalendar={selectedDefaultDate >= getDate(user)}/>
                            </Grid>
                        </Grid>
                    }
                    {/* {
                        selection == "product" &&
                        <Grid item container xs={6}>
                            <CounterRentorBuy onChangeProducts={addProduct} onChangeRents={addRent} />
                        </Grid>
                    } */}
                    <Grid item container xs={6} sx={{height: '65vh', overflow: 'auto'}}>
                        <Grid item xs={12}>
                            <SummaryComponent bookings={booking} rents={rents} products={products}
                                deleteBookings={onDeleteBookings}
                            />
                        </Grid>
                    </Grid>
                    <Grid item p={0} container xs={12} sx={{position: 'absolute', bottom: 8}} spacing={2}>
                        <Grid p={0} pt={0} pl={0} pr={0} xs={6} item alignContent={"flex-end"}>
                        {
                            selection == "booking" &&
                            <MKButton color="white" 
                                    disabled={loading} 
                                    // fullWidth
                                    onClick={() => {
                                        addBooking()
                                    }}
                                    sx={{
                                        border: 1,
                                        color: color.primary
                                    }}
                                >
                                    <Icon>more_time</Icon>
                                    <span style={{marginTop: 2}}>{"Add"}</span>
                            </MKButton>
                        }
                        </Grid>
                        <Grid p={0} pt={0} pr={2} pl={0} item container xs
                            sx={{
                                backgroundColor: 'white !important'
                            }}
                        >
                            {/* <Grid mb={2}> */}
                                {/* <PaymentComponent/> */}
                            {/* </Grid> */}
                            <Grid item container xs={12}>
                                <Grid item xs={6} textAlign={"left"}>
                                    <TCLabel>Total {calculateSummary().totalSession} Session</TCLabel>
                                </Grid>
                                <Grid item xs={6} textAlign={"right"}>
                                    <TCLabel bold>Total {currencyFormat(calculateSummary().totalPrice)}</TCLabel>
                                </Grid>
                            </Grid>
                            <Grid pt={0} xs={12} md={12} lg={12} item style={{textAlign: 'center'}}>
                                <MKButton color="primary" fullWidth 
                                disabled={loading || booking.length <= 0}
                                onClick={() => {
                                    payment()
                                }}>
                                    Pay
                                </MKButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            {
                paymentId &&
                <Grid xs={12}>
                    <CounterPayment order_id={paymentId}/>
                </Grid>
            }
        </Grid>
    )
}

function HoursComponent ({
    form,
    onChange,
    availableHours,
    rerender
}) {

    useEffect(() => {
        console.log('here check rerender', availableHours)
    }, [availableHours, rerender])

    const updateSelectedTime = (value) => {

        let index = form.hours.indexOf(value.label);
        let newForm = form.hours;
        let sessions = form.totalSessions;
        let total = form.totalPrice;
        
        if (value.active && index < 0) {
            
            newForm = [ ...form.hours, value.label ];
            sessions += 1;
            total += value.nominee;

        } else if (index >= 0) {
            
            newForm.splice(index, 1);
            sessions -= 1;
            total -= value.nominee;

        }

        onChange({hours: newForm, totalSessions: sessions, totalPrice: total})
    }

    return (
        <Grid container>
            <Grid mb={1} item>
                <TCLabel bold>Time</TCLabel>
            </Grid>
            <Grid item container spacing={2}>
            {
                availableHours.map( value => {
                    return (
                        <Grid item xs={2}>
                            <div className={
                                    value.idle ? 'selection-idle' : 
                                    value.booked ? value.ownBooked ? 'selection-booked-own' : 'selection-booked' : 
                                    value.active ? 
                                        form.hours.indexOf(value.label) >= 0 ? 
                                        'selection-selected' : 
                                        'selection-available' 
                                    : 'selection-disabled'
                                } 
                                style={{textAlign: 'center', padding: 5, 
                                    minWidth: 25, minHeight: 50, borderRadius: 8
                                }}
                                onClick={() => {
                                    if (value.idle || value.booked) { 
                                        Confirm("", value.idle ? "Awaiting payment. Please check again" : value.ownBooked ? "My booking" : "Court is booked")
                                        return; 
                                    }
                                    updateSelectedTime(value)
                                }}
                            >
                                {/* <MKTypography variant="small" fontWeight="regular" color="inherit">{value.label}</MKTypography> */}
                                <div>{value.label}</div>
                                {
                                    value.idle ? <Icon>access_time</Icon> : 
                                    value.ownBooked ? <Icon>event_available</Icon> : 
                                    value.price &&
                                    // <MKTypography variant="body2" fontWeight="regular" color="inherit">{value.price}</MKTypography>
                                    <div>{value.price}</div>
                                }
                            </div>
                        </Grid>
                    )
                })
            }
            </Grid>
        </Grid>
    )
}

function CourtComponent ({
    courtInfo,
    form,
    courts,
    onChange,
    checkBookingHours,
    rerender
}) {

    const [ imageDetail, setImageDetail ] = useState(false)

    return (
        <Grid container>
            <Grid item mb={1} xs={12} md={12} lg={12}>
                <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                    <div style={{marginLeft: 0, display: 'flex', justifyContent: 'center'}}>
                        <TCLabel bold>Court</TCLabel>
                    </div>
                    <div style={{width: '100%', textAlign: 'right'}}>
                        {/* <MKButton size="small" type="submit" variant="gradient" color="primary">View Layout</MKButton> */}
                        <div className='mini-btn-custom' onClick={() => {
                            setImageDetail(true)
                        }}>
                            <div>View Layout</div>
                        </div>
                    </div>
                </div>
            </Grid>
            {
                courtType.map( (type, index) => {
                    return (
                        <Grid xs={4} mb={2} item container style={{
                            border: '1px dashed #808080',
                            borderRight: (index == courtType.length - 1) && '1px dashed #808080',
                            padding: 4,
                            borderRadius: 10
                        }}>
                            <Grid xs={12} item textAlign={"center"}>
                                <TCLabel>{type.label}</TCLabel>
                            </Grid>
                            <Grid item container style={{paddingTop: 3}} justifyContent={"center"}>
                            {
                                courts.map( value => {
                                    if (value.courtType === type.value)  {
                                        return (
                                            <Grid item>
                                                <div className={form.court === value._id ? 'selection-selected' : 'selection-available'} style={{textAlign: 'center', padding: 2,
                                                    paddingLeft: 8, paddingRight: 8, 
                                                    minWidth: 25, marginRight: 2, borderRadius: 8}}
                                                    onClick={() => {
                                                        let courtInfo = checkBookingHours(new Date(form.date), value._id)
                                                        onChange({
                                                            court: value._id, 
                                                            ...courtInfo
                                                            // hours: [], 
                                                            // totalSessions: 0,
                                                            // totalPrice: 0
                                                        })
                                                    }}
                                                >
                                                    {value.name}
                                                </div>
                                            </Grid>
                                        )
                                    }
                                })
                            }
                            </Grid>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

function getDate(user) {
    return new Date(moment(addMonths(new Date(), user ? user.advancedSchedule ? user.advancedSchedule : (user.activeBenefits && user.activeBenefits.advancedMonths) ? user.activeBenefits.advancedMonths : 1 : 1)).endOf('month'))
}

export default CounterBooking
