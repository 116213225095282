import React, { useEffect, useState } from "react";

import tennis from "../../../assets/logo/tennis.png"
// import { useNavigate } from "react-router-dom";

import MKButton from "components/MKButton";
import { Grid } from "@mui/material";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";

import MuiPhone from "components/TopCourtComponents/Input/CountryPickerInput";
import "react-international-phone/style.css";
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent";
import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput";
import { setUserInfo } from "helper/localStorage";
import { checkCounterPasswordService } from "service/counterService";
import { Confirm } from "helper/showAlert";

function CounterLogin ({

}) {  

    // const navigate = useNavigate()

    const [ phoneNumber, setPhoneNumber ] = useState("+62");
    const [ loading, showLoading ] = useState(false);
    const [ password, setPassword ] = useState("");

    useEffect(() => {
        // windowVerifier()
    }, [])

    const checkPassword = async () => {
        let { data, error } = await checkCounterPasswordService(phoneNumber, password)
        if (error) { Confirm("", error) }
        if (data) { setUserInfo(data); window.location.reload(); }
    }   

    return (
        <Grid container justifyContent="center">
            <>
                <Grid container xs={12} md={12} lg={12} item style={{marginTop: 8 }} justifyItems={"center"}>
                    <Grid item><img src={tennis} width={50} height={50}/></Grid>
                    <Grid item pt={1.8}><TCTitleLabel>Welcome Back</TCTitleLabel></Grid>
                </Grid>
                <Grid xs={12} md={12} lg={12} item style={{marginTop: 8 }}>
                    <TCLabel>Mobile number</TCLabel> 
                </Grid>
                <Grid xs={12} md={12} lg={12} item style={{marginTop: 4 }} container>
                    <MuiPhone 
                        value={phoneNumber} onChange={setPhoneNumber}
                    />
                </Grid>
                <Grid xs={12} pt={1}>
                    <TCInput type={"password"} label={"Enter Password"} value={password} 
                    enterAction={() => {
                        checkPassword()
                    }}
                    onChange={(e) => {
                        setPassword(e.currentTarget.value)
                    }} />
                </Grid>
                <Grid xs={12} pt={1}>
                    <MKButton fullWidth 
                        color="primary"
                        onClick={() => {
                            checkPassword()
                        }}
                    >Confirm</MKButton>
                </Grid>
            </>
            {
                loading &&
                <Loading/>
            }
            {/* <div id="recaptcha-container"/> */}
        </Grid>
    )
}

export default CounterLogin;