import welcome from '../../assets/sounds/WelcomeCourt.mp3';
import qrnotvalid from '../../assets/sounds/QRnotvalid.mp3';
import indoor from '../../assets/sounds/Indoor.mp3';
import outdoor from '../../assets/sounds/Outdoor.mp3';
import hitting from '../../assets/sounds/Hitting.mp3';
import courtsound from '../../assets/sounds/Court.mp3';

import one from '../../assets/sounds/One.mp3';
import two from '../../assets/sounds/Two.mp3';
import three from '../../assets/sounds/Three.mp3';
import four from '../../assets/sounds/Four.mp3';
import five from '../../assets/sounds/Five.mp3';
import six from '../../assets/sounds/Six.mp3';
import seven from '../../assets/sounds/Seven.mp3';
import eight from '../../assets/sounds/Eight.mp3';
import nine from '../../assets/sounds/Nine.mp3';
import ten from '../../assets/sounds/Ten.mp3';
import zero from '../../assets/sounds/Zero.mp3';

// untuk testing
// 1712997799985-661a45a7ce8b9f5ac500b396-65f2ca9f744507d419717fbd-653f5d9006520fccd4ab4604

export const playSound = async (data, navigate) => {
    await playAudio(new Audio(welcome))
    console.log('playsound', data)
    let { court } = data
    // await playAudio(new Audio(courtsound))
    let { courtType, name } = court
    if (courtType == 'indoor') {
        await playAudio(new Audio(indoor))
    }
    if (courtType == 'outdoor') {
        await playAudio(new Audio(outdoor))
    }
    if (courtType == 'hitting_room') {
        await playAudio(new Audio(hitting))
    }
    if (name == "1" || name == "one" || name == "H1") {
        await playAudio(new Audio(one))
    }
    if (name == "2" || name == "two" || name == "H2") {
        await playAudio(new Audio(two))
    }
    if (name == "3" || name == "three") {
        await playAudio(new Audio(three))
    }
    if (name == "4" || name == "four") {
        await playAudio(new Audio(four))
    }
    if (name == "5" || name == "five") {
        await playAudio(new Audio(five))
    }
    if (name == "6" || name == "six") {
        await playAudio(new Audio(six))
    }
    if (name == "7" || name == "seven") {
        await playAudio(new Audio(seven))
    }
    if (name == "8" || name == "eight") {
        await playAudio(new Audio(eight))
    }
    if (name == "9" || name == "nine") {
        await playAudio(new Audio(nine))
    }
    // navigate(0)
}

export const playSoundError = async (data, navigate) => {
    await playAudio(new Audio(qrnotvalid))
    console.log('playsound', data)
    navigate(0)
}

function playAudio(audio){
    return new Promise(res=>{
      audio.play()
      audio.onended = res
    })
}