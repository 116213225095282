import React, { useEffect, useState } from "react";
import { Card, Grid, Input } from "@mui/material";
import { TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import DialogHeader from "components/SlideDialog/DialogHeader";
import MKButton from "components/MKButton";
import ProductListHeader from "./ProductListHeader";
import { confirmPickup } from "service/counterService";


function PickupConfirmation ({
    data,
    orderInfo,
    closeDialog
}) {

    const [ barcode, setBarcode ] = useState("")
    const [ pickupCheckId, setPickupCheckId ] = useState([])

    useEffect(() => {
        if (data && (data.info && data.status == "alert")) {
            const closeAlert = setTimeout(() => {
                closeDialog()
            }, 2000)
            return () => clearTimeout(closeAlert)
        }
    }, [data])

    useEffect(() => {
        console.log('Hello', pickupCheckId)
    }, [pickupCheckId])

    // function to check uncheck
    const checkUncheck = (_id, array, setCheckId) => {
        // check if _id exist then _id splice, else push
        console.log('here check _id', _id)
        let newCheckId = [...array]
        let index = newCheckId.indexOf(_id)
        if (index >= 0) {
            // newCheckId = newCheckId.splice(index, 1)
            // console.log('here check index', index, newCheckId)
            newCheckId.splice(index, 1)
            setCheckId(newCheckId)
        } else {
            newCheckId.push(_id)
            setCheckId(newCheckId)
        }
    }

    const confirmPickupButton = async () => {
        let pickupConfirm = await confirmPickup({
            _id: data.order._id,
            staff: data.staff
        })
        if (pickupConfirm.error) { console.log(pickupConfirm.error); closeDialog('QR not valid') }
        if (pickupConfirm.data) { closeDialog('Order completed') }
        // confirmRent
    }

    useEffect(() => {
        if (data && (data.info && data.status == "alert")) {
            const closeAlert = setTimeout(() => {
                closeDialog()
            }, 2000)
            return () => clearTimeout(closeAlert)
        }
    }, [data])

    return (
        <Grid textAlign={"center"} pb={3}>
            <DialogHeader closeDialog={() => {closeDialog()}}/>
            {
                data ? 
                data.info ?
                <TCTitleLabel>{data.info}</TCTitleLabel>
                :
                <Grid>
                    {/* {
                        data.staff &&
                        <Grid>
                            <TCLabel>Staff Name : </TCLabel>
                            <TCTitleLabel>{data.staff.name}</TCTitleLabel>
                        </Grid>
                    } */}
                    {
                        data.orderList &&
                        <Grid item container xs>
                            <ProductListHeader order={data.order} productList={data.orderList}
                                setCheckId={setPickupCheckId}
                                checkId={pickupCheckId}
                                checkUncheck={checkUncheck}
                            />
                            <Grid mt={4} xs={12} textAlign={"center"}>
                                <MKButton color="primary" disabled={pickupCheckId.length != data.orderList.length}
                                    onClick={() => {
                                        console.log("Hello world")
                                        confirmPickupButton()
                                    }
                                }>Confirm</MKButton>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                :
                <TCTitleLabel>Scan Staff Barcode</TCTitleLabel>
            }
        </Grid>
    )
}

export default PickupConfirmation