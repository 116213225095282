import React from "react";

// firebase
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";


const firebaseConfig = {
    apiKey: "AIzaSyDBnIi7tbrpOtfxIwtUM4e-5Slg3jXcCkY",
    authDomain: "staging-topcourt.firebaseapp.com",
    projectId: "staging-topcourt",
    storageBucket: "staging-topcourt.appspot.com",
    messagingSenderId: "909898101146",
    appId: "1:909898101146:web:ad064bf325a2d666790a23",
    measurementId: "G-ZN8DJH0ZGV"
};

const app = initializeApp(firebaseConfig)
export const auth = getAuth(app)
// export const auth = () => {
//     let auth = getAuth(app);
//     window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {});
//     return auth
// }

export default app