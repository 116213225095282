import React, { useEffect, useState } from "react"

import { Card, Grid, Icon} from "@mui/material"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import { TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel"

import DeleteIcon from '@mui/icons-material/Delete';
import { courtTypeObj } from "helper/constant"
import { currencyFormat } from "helper/numberFormat"
import moment from "moment"
import { timeStringFormat } from "helper/constant"
import { color } from "helper/constant"

function SummaryComponent ({
    rerender,
    deleteBookings,
    bookings,
    rents,
    products
}) {

    console.log(bookings)

    const [ cartInfo, setCartInfo ] = useState({
        bookings: [],
        rents: [],
        products: []
    })

    return (
        <Grid container p={1} pt={0} textAlign={"left"}>
            <Grid item xs={12}>
                <TCTitleLabel bold><Icon>shopping_cart</Icon></TCTitleLabel>
            </Grid>
            {
                (cartInfo.bookings.length == 0 && cartInfo.rents.length == 0 && cartInfo.products.length == 0) &&
                <Grid item xs={12}>
                    
                </Grid>
            }
            <Grid item container pb={8} xs={12} sx={{overflow: 'auto'}}>
            {
                bookings.length > 0 &&
                <Grid item container xs={12}>
                {
                    bookings.map(value => {
                        return (<Grid xs={12} item p={1}>
                            <BookingCard v={value} deleteBookings={deleteBookings}/>
                        </Grid>)
                    })
                }
                </Grid>
            }
            {
                cartInfo.rents.length > 0 &&
                <Grid item container xs={12}>
                {
                    cartInfo.rents.map(value => {
                        return (<Grid xs={12} item>Rents</Grid>)
                    })
                }
                </Grid>
            }
            {
                cartInfo.products.length > 0 &&
                <Grid item container xs={12}>
                {
                    cartInfo.products.map(value => {
                        return (<Grid xs={12} item>Product</Grid>)
                    })
                }
                </Grid>
            }
            </Grid>
        </Grid>
    )
}

function BookingCard ({
    v,
    deleteBookings
}) {

    return (
        <Card>
            <div className='custom-body' style={{padding: 10}}>
                <TCLabel style={{fontSize: 13, fontWeight: 'bold'}}>{moment(v.date).format("ddd DD/MM/YYYY")}</TCLabel>
                <>
                    <div style={{display: 'flex', marginTop: 2}} onClick={() => {
                        onSelectBooking(v)
                    }}>
                        <div style={{width: '100%'}}>
                            <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{courtTypeObj[v.court.courtType]} {v.court.name}</TCLabel>
                            <Grid container direction={'row'}>
                            {
                                v.hours.map((h, index) => {
                                    return <TCLabel style={{marginRight: 3, fontSize: 12}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)} 
                                        ${index < (v.hours.length - 1) ? " | " : ""}`}</TCLabel>
                                })
                            }    
                            </Grid>
                            <TCLabel>Total: <span style={{fontWeight: 'bold'}}>{currencyFormat(v.totalPrice)}</span></TCLabel>
                        </div>
                        <div style={{alignItems: 'right'}} onClick={(e) => {
                            e.stopPropagation()
                            deleteBookings({...v, isExpired: false})
                        }}>
                            <DeleteIcon style={{width: 20, height: 20, color: color.pink}}/>
                        </div>
                    </div>
                    {/* {(idx < (value.data.length - 1)) && <div className="separation-line" />} */}
                </>
            </div>
        </Card>
    )
}

export default SummaryComponent