import React, { useEffect, useState } from "react"
import { Card, Grid } from "@mui/material"
import { getSellOrder } from "service/counterService"
import moment from "moment"
import SlideAlert from "components/SlideInAlert/SlideAlert"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import MKButton from "components/MKButton"
import PickupConfirmation from "./component/PickupConfirmation"
import { TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent"

function CounterPickup ({
    rerender,
    setOpenRentAlert
}) { 

    const [ pickupData, setPickUpData ] = useState([])
    const [ openAlert, setOpenAlert ] = useState(false)
    const [ loading , setLoading] = useState(true)

    useEffect(() => {
        loadPickUpOrder()
    }, [rerender])

    useEffect(() => {
        console.log(pickupData)
    }, [pickupData])

    const loadPickUpOrder = async () => {
        // load the order is not yet rent
        // rent flow is consist of paid -> rent -> complete
        let date = moment(new Date()).format('YYYY-MM-DD')
        let { data, error } = await getSellOrder(date)
        setLoading(false)
        if (error) { throw error }
        if (data) {
            setPickUpData(data)
        }
    }

    return (
        <div style={{
            paddingTop: 24
        }}>
            {
                loading &&
                <Loading/>
            }
            {
                (pickupData.length == 0 && !loading) &&
                <Grid container alignItems={"center"} textAlign={"center"}>
                    <Grid item xs={12} mt={12}>
                        <TCTitleLabel>No Data</TCTitleLabel>
                    </Grid>
                </Grid>
            }
            {/* Product name and image, Booking Id, Button to scan */}
            {
                pickupData.map( value => {
                    return (
                        <Grid container alignItems={"center"} sx={{
                            borderBottom: '1px dotted grey'
                        }}>
                            <Grid item container xs={9.1} p={1} sx={{
                                borderRight: '1px dotted grey'
                            }}>
                                <Grid><TCLabel bold>{value.user.name}</TCLabel></Grid>
                                <Grid container xs={12}>
                                    <Grid item>
                                        <TCLabel style={{
                                            fontWeight: 'bold'
                                        }}>{`${moment(value.createdDate).format('DD/MM/YYYY HH:mm:ss')}`}</TCLabel>
                                    </Grid>
                                </Grid>
                                <Grid item container spacing={1}>
                                {
                                    value.orderItem.map(v => {
                                        return (
                                            <Grid item xs={3}>
                                                <Card>
                                                    <Grid container alignItems={"center"}>
                                                        <Grid item xs={6} textAlign={"center"}>
                                                            <img src={v.productInfo.image} width={'100%'} 
                                                                style={{
                                                                    objectFit: 'contain',
                                                                    borderTopLeftRadius: 8, borderTopRightRadius: 8, minHeight: 60, maxHeight: 80,
                                                                    minWidth: 60, maxWidth: 60
                                                                }}/>
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Grid xs={12}><TCLabel bold>{v.productInfo.category}</TCLabel></Grid>
                                                            <TCLabel>{v.productInfo.name}</TCLabel>
                                                            <TCLabel>Qty {v.qty}</TCLabel>
                                                        </Grid>
                                                    </Grid>
                                                </Card>
                                            </Grid>
                                        )
                                    })
                                }
                                </Grid>
                            </Grid>
                            <Grid item xs={2.5} p={1} textAlign={"center"}>
                                {
                                    !value.pickupStatus || (value.pickupStatus && value.pickupStatus.length == 0) ?
                                    <Grid item container justifyContent={"center"}>
                                        <Grid item>
                                            <TCLabel>
                                                Pick Up
                                            </TCLabel>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid item container justifyContent={"center"}>
                                        <Grid item>
                                            <TCLabel>
                                                Completed {moment(value.pickupStatus[value.pickupStatus.length - 1].date).format('DD-MM-YYYY HH:mm:ss')} by
                                                {value.pickupStatus[value.pickupStatus.length - 1].admin.name}
                                            </TCLabel>
                                        </Grid>
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    )
                })
            }
            <SlideAlert
                isOpen={openAlert}
                setIsOpen={setOpenAlert}
            >
                {/* Scan Staff Barcode First */}
                {/* Scan Customer Barcode First */}
                <PickupConfirmation />
            </SlideAlert>
        </div>
    )
}

export default CounterPickup