import Counter from "counter/Counter";


const counterRoutes = [
    {
      name: "Counter",
      route: "/",
      component: <Counter />,
    },
]

export default counterRoutes