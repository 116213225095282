import moment from "moment";

export const toUTC = (d, moment = false, format = 'YYYY-MM-DD') => {
    let offSetCount = d.getTimezoneOffset()
    if (offSetCount < 0) {  
        if (moment) { return moment(new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000)).format(format) }
        else { return new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000)}   
    } else {
        if (moment) { return moment(new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000)).format(format) }
        else { return new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000)}   
    }
}

export const toGMT7 = (d, format = 'YYYY-MM-DD') => {
    // let offSetCount = d.getTimezoneOffset()
    // console.log('here gmt7', d, offSetCount, d.toISOString())
    // console.log('GMT-check, d offsetCount', d, offSetCount)
    console.log(moment(d.toISOString()).format(format))
    return moment(d.toISOString()).format(format)
    // if (offSetCount > 0) {
    //     // console.log('GMT-check, offsetCount < 0', moment(new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000)).utcOffset(7).format(format))
    //     // console.log('GMT-check, offsetCount < 0 local', moment(toLocalGMT(d)).utcOffset(7).format(format))
    //     // return moment(new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000)).utcOffset(7).format(format)
    //     return moment(d.toISOString()).format(format)
    // } else {
    //     // console.log('GMT-check, offsetCount > 0', moment(new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000)).utcOffset(7).format(format))
    //     // console.log('GMT-check, offsetCount > 0 local', moment(toLocalGMT(d)).utcOffset(7).format(format))
    //     // return moment(new Date(d.getTime() + d.getTimezoneOffset() * 60 * 1000)).utcOffset(7).format(format)
    //     return moment(d.toISOString()).format(format)
    // }
}

export const momentFormat = (d, format = 'YYYY-MM-DD') => {
    return moment(d).format(format)
}

export const toLocalGMT = (d) => {
    return moment(d).toDate()
}

// Old - Cannot Handle UTC -
// export const toUTC = (d, moment = false, format = 'YYYY-MM-DD') => {
//     if (moment) { return moment(new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000)).format(format) }
//     else { return new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000)}
// }

// export const toGMT7 = (d, format = 'YYYY-MM-DD') => {
//     return moment(new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000)).utcOffset(7).format(format)
// }