import React, { useEffect, useState } from "react";
import { Box, Card, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import DialogHeader from "components/SlideDialog/DialogHeader";
import MKButton from "components/MKButton";
import { orderStatusMap } from "helper/constant";
import { color } from "helper/constant";
import { timeStringFormat } from "helper/constant";
import { courtTypeObj } from "helper/constant";
import moment from "moment";
import { sortInformation } from "helper/productSort";


function ProductListHeader ({
    order, productList,
    checkId,
    setCheckId,
    checkUncheck
}) {
    return (
        <>
            <div style={{width: 'fit-content'}}>
                <TCLabel>Booking ID </TCLabel>
                <div style={{backgroundColor: color.blueIndoor_2, width: '100%', height: '1px'}}/>
            </div>
            <Grid container>
                <Grid item>
                    <TCTitleLabel>{order.bookingId}</TCTitleLabel>
                </Grid>
                <Grid item pt={0.5} pl={1}>
                    <Box>
                        {
                            order &&
                            <div className='custom-body' style={{
                                padding: '1px 5px',
                                borderRadius: 5,
                                color: order.isPaid ? 'white' : order.lastStatus != "failed" ? 'white' : 'white',
                                fontSize: 10,
                                fontWeight: 'bold',
                                width: 'fit-content',
                                backgroundColor: order.isPaid ? color.green : order.lastStatus != "failed" ? color.orange : color.pink}}>
                                {orderStatusMap(order)}
                                {/* {order.isPaid ? "Paid" : order.lastStatus != "failed" ? "Waiting Payment" : orderStatus.failed} */}
                            </div> 
                        }
                    </Box>
                </Grid>
            </Grid>
            <div style={{paddingTop: 8, marginBottom: 10, width: 'fit-content'}}>
                <TCLabel>{"Detail"}</TCLabel>
                <div style={{backgroundColor: color.blueIndoor_2, width: '100%', height: '1px'}}/>
            </div>
            <Grid item container>
            {
                // productList
                sortInformation(productList).map(v => {
                // productList.map(v => {
                    return (
                        <Grid item xs={12} container alignItems={"center"} mt={.5}>
                            <Grid item xs={1}>
                                <FormControlLabel control={<Checkbox 
                                    checked={checkId.indexOf(v._id) >= 0 ? true : false} 
                                    onChange={() => { 
                                        checkUncheck(v._id, checkId, setCheckId)
                                    }} />} /> 
                            </Grid>
                            <Grid item xs>
                                <Card>
                                    <Grid p={1} pb={0} textAlign={"left"}>
                                        <TCLabel style={{fontSize: 13, fontWeight: 'bold'}}>{moment(order.date).format("ddd DD/MM/YYYY")}</TCLabel>
                                    </Grid>
                                    <Grid item container alignItems={"center"} p={1}>
                                        <Grid item>
                                            <img src={v.productInfo.image} width={'100%'} 
                                                style={{
                                                    objectFit: 'contain',
                                                    borderTopLeftRadius: 8, borderTopRightRadius: 8, minHeight: 120, maxHeight: 120,
                                                    minWidth: 120, maxWidth: 120, marginRight: 16
                                                }}/>
                                        </Grid>
                                        <Grid item style={{
                                                minHeight: 40
                                            }} textAlign={"left"}>
                                            <TCLabel style={{color: color.blueIndoor_1}} bold>{v.productInfo.category} : {v.productInfo.name}</TCLabel>
                                            {
                                                order.orderType == "sell" &&
                                                <TCLabel>Qty {v.qty}</TCLabel>
                                            }
                                            {
                                                order.orderType == "rent" &&
                                                <>
                                                    <Grid container>
                                                        <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{courtTypeObj[v.court.courtType]} {v.court.name}</TCLabel>
                                                    </Grid>
                                                    <Grid container direction={'row'}>
                                                    {
                                                        v.hours.map((h, index) => {
                                                            return <TCLabel style={{marginRight: 3, fontSize: 12}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)} 
                                                                ${index < (v.hours.length - 1) ? " | " : ""}`}</TCLabel>
                                                        })
                                                    }    
                                                    </Grid>
                                                </>
                                            }
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>
                    )
                })
            }
            </Grid>
        </>
    )
}

export default ProductListHeader