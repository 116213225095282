import { client, errorValidation } from "./service";

export const checkCounterPasswordService = async function (
  phoneNumber, password, isCounter = true
) {
  try {
    let response = await client.post(`/counter/check-password`, {
      phoneNumber: phoneNumber || "",
      password: password,
      isCounter
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    return errorValidation(e)
  }
}

export const checkQRService = async function (body) {
  try {
    let response = await client.post(`/counter/qr-check`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (e) {
    // return { error: e.response.data.errorCode }
    return errorValidation(e)
  }
}

export const loadBookingService = async function (date) {
  try {
    let response = await client.get(`/counter/schedule?date=${date}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (e) {
    return errorValidation(e)
  }
}

export const deleteBookingService = async function (userInfo) {
  try {
    let response = await client.get(`/counter/delete-cart?userInfo=${userInfo}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (e) {
    return errorValidation(e)
  }
}

export const getRentOrder = async function (date) {
  try {
    let response = await client.get(`/rent-order?date=${date}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    return errorValidation(e)
  }
}

export const getSellOrder = async function (date) {
  try {
    let response = await client.get(`/sell-order?date=${date}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    return errorValidation(e)
  }
}

export const checkStaffBarcode = async function (body) {
  try {
    let response = await client.post(`/check-staff-barcode`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (e) {
    return errorValidation(e)
  }
}

export const checkOrderBarcode = async function (body) {
  try {
    let response = await client.post(`/check-order-barcode`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (error) {
    return errorValidation(error)
  }
}

export const confirmRent = async function (body) {
  try {
    let response = await client.post(`/confirm-rent`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (e) {
    return errorValidation(e)
  }
}

export const confirmPickup = async function (body) {
  try {
    let response = await client.post(`/confirm-pickup`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data }
  } catch (e) {
    return errorValidation(e)
  }
}

export const checkReturnOrder = async function (body) {
  try {
    let response = await client.post(`/return-order`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;

    return { data }
  } catch (e) {
    return errorValidation(e)
  }
}