import { reactLocalStorage } from "reactjs-localstorage";

const LOCALSTORAGECONSTANT = {
    TOP_COURT_USER : "topCourtCounterUser",
    TOP_COURT_PLACE : "topCourtCounterCurrentPlace",
    CART: "topCourtCounterCart"
}

export const getCourtInfo = {
    _id: '653f5d9006520fccd4ab4604'
}

// User Info ~> Counter ID

export const setCounterInfo = function(data) {
    reactLocalStorage.setObject(LOCALSTORAGECONSTANT.TOP_COURT_USER, data);
    return true
}
export const getCounterInfo = reactLocalStorage.get(LOCALSTORAGECONSTANT.TOP_COURT_USER)
export const deleteCounterInfo = function ()  { reactLocalStorage.remove(LOCALSTORAGECONSTANT.TOP_COURT_USER); return true}