import { client, errorValidation } from "./service";

export const getCourtInformation = async function (place) {
  try {
      let response = await client.get(`/courts?place=${place}`);
      if (response.data.statusCode !== 200) { throw response.data.data; }
      let data = response.data.data;
      return { data };
  } catch (e) {
      // return { error: e.response.data.errorCode }
      return errorValidation(e)
  }
}

export const getCourtSchedule = async function (court, day, userId) {
  try {
    let response = await client.get(`/court-schedule?court=${court}&day=${day}&userId=${userId || ""}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const getScheduleCart = async function ({
  placeId
}) {
  try {
    let response = await client.get(`/cart?placeId=${placeId}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const addScheduleCart = async function (body, placeId) {
  try {
    let response = await client.post(`/cart-add?placeId=${placeId}`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const deleteScheduleCart = async function (cartId, placeId, isExpired) {
  try {
    let response = await client.delete(`/cart-delete?cartId=${cartId}&placeId=${placeId}&isExpired=${isExpired}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.errorCode };
    return errorValidation(e)
  }
}

export const setValuePack = async function (body) {
  try {
    let response = await client.post(`/cart-use-valuepack`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.message };
    return errorValidation(e)
  }
}

export const getUserCartSummary = async function (placeId) {
  try {
    let response = await client.get(`/cart-user?placeId=${placeId}`);
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (error) {
    return errorValidation(e)
  }
}

export const submitOrder = async function (body) {
  try {
    let response = await client.post(`/cart-checkout`, {
      ...body
    });
    if (response.data.statusCode !== 200) { throw response.data.data; }
    let data = response.data.data;
    return { data };
  } catch (e) {
    // return { error: e.response.data.message };
    return errorValidation(e)
  }
}