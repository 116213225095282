import React, { useState, useEffect } from "react"
import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar"
import { Grid, Button, Card, Icon, Box } from "@mui/material"

import { currencyFormat } from 'helper/numberFormat';

import { getCartPackage, checkoutPackage } from "service/packagesService";
import { getOrderDetail, getItemOrderDetail } from "service/orderService";
import { getUserInfo } from "helper/localStorage";
import { TCTitleLabel, TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";


import moment from "moment";
import { color, timeStringFormat } from "helper/constant";

import Countdown from 'react-countdown';

import barcode from "../assets/payment/qrbca.jpeg";

import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent";
import SlideAlert from "components/SlideInAlert/SlideAlert";

import { courtTypeObj } from "helper/constant";
import QRPage from "pages/Orders/QRPage";
import { orderStatusMap } from "helper/constant";
import OrderInformation from "components/TopCourtComponents/AlertInfo/OrderInfo";
import padlock from "../assets/padlock.png";
import { useNavigate } from "react-router-dom";

function CounterPayment({
    order_id
}) {

    const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null
    // const url = window.location.href.split('/')
    const type = "booking"
    const orderId = order_id

    const [ order, setOrder ] = useState(null);
    const [ showQR, setShowQR ] = useState({
        open: false,
        uniqueCode: "",
        courtInfo: null
    });
    const [ userCart, setUserCart ] = useState({
        packages: [],
        subTotal: 0,
        total: 0,
    });
    const [ loading, setLoading ] = useState(false)
    const [ showImageDetail, setShowImageDetail ] = useState({
        open: false,
        image: null
    })

    const [ showPaymentQR, setShowPaymentQR ] = useState(true)
    const [ expiredQR, setExpiredQR ] = useState(false)

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        let interval = null
        if (order) {
            if (new Date() >= new Date(order.createdDate).getTime() + 180000) {
                setExpiredQR(true)
            }
            interval = setInterval(() => {
                if (new Date() >= new Date(order.createdDate).getTime() + 180000) {
                    setExpiredQR(true)
                }
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [order])

    const loadData = async () => {
        try {
            if (type == "value-pack") {
                if (orderId) {
                    let { data, error } = await getOrderDetail(orderId)
                    if (error) { throw error }
                    if (data) { setOrder(data) }
                } else {
                    let { data, error } = await getCartPackage(userInfo._id)
                    if (error) { throw error }
                    if (data) { setUserCart(data) }
                }
            } else if (type == "booking" || type == "booking?page=cart") {
                setLoading(true)
                let { data, error } = await getOrderDetail(orderId)
                if (error) { throw error }
                if (data) { 
                    console.log(data); 
                    let newBookings = []
                    let bookingData = data.orderSessions || []
                    for (let i = 0; i < bookingData.length; i ++) {
                        if (newBookings.length == 0) {
                            newBookings.push({
                                date: bookingData[i].date,
                                data: [ bookingData[i] ]
                            })
                        } else {
                            let isSimiliar = false
                            for (let j = 0; j < newBookings.length; j ++) {
                                if (newBookings[j].date == bookingData[i].date) {
                                    isSimiliar = true
                                    newBookings[j].data.push(bookingData[i])
                                    break;
                                }
                            }
                            if (!isSimiliar) {
                                newBookings.push({
                                    date: bookingData[i].date,
                                    data: [ bookingData[i] ]
                                })
                            }
                        }
                    }
                    setOrder({
                        ...data,
                        newBookings
                    }) 
                    setLoading(false)
                }
                // setOrder()
                // let { data, error } = await 
            } else if (type == "add-ons" || type == "add-ons?page=add-ons") {
                setLoading(true)
                let { data, error } = await getItemOrderDetail(orderId)
                // let { data, error } = await getOrderDetail(orderId)
                if (error) { throw error }
                if (data) { 
                    console.log(data); 
                    setOrder({
                        ...data
                    }) 
                    setLoading(false)
                }
                
            } else {
                setLoading(false)
                alert("Wrong Page")
            }
        } catch (error) {
            if (error.includes("login")) {
                window.location.href = "/"
            }
        }
    }

    return (
        <>
        <Grid container xs={12}>
            <Grid item xs={6}>
            {
                (type == "value-pack" && order) &&
                <>
                    <Grid sx={{pl: 0, pr: 0}}>
                        <PackageItemView order={order} />
                    </Grid>
                </>
            }
            {
                ((type == "booking" || type == "booking?page=cart") && order) &&
                <Grid sx={{pl: 0, pr: 0}}>
                    <BookingView order={order} setShowQR={setShowQR}/>
                </Grid>
            }
            {
                ((type == "add-ons" || type == "add-ons?page=add-ons") && order) &&
                <Grid sx={{pl: 0, pr: 0}}>
                    <ItemOrderView order={order} setShowQR={setShowQR}/>
                </Grid>
            }
            </Grid>
            <Grid>
            {
                (order && !order.isPaid && order.lastStatus !== "failed") &&
                <Grid container justifyContent={"center"} direction={"column"}>
                    <Grid container justifyContent={"center"} spacing={0.5}>
                        <Grid item sx={{marginTop: 0.22}} justifyContent={"center"} textAlign={"center"}>
                            <TCLabel style={{fontSize: 13}}>Scan to complete payment within</TCLabel>
                        </Grid>
                        <Grid item justifyContent={"center"} textAlign={"center"}>
                            <TCLabel style={{fontSize: 15}} bold>
                                <Countdown 
                                    daysInHours={true}
                                    zeroPadDays={0}
                                    zeroPadTime={2} date={order ? new Date(order.createdDate).getTime() + 180000 : Date.now() + 180000}
                                    renderer={({ formatted: { hours, minutes, seconds } }) => {
                                        return (
                                            <>
                                                {minutes}:{seconds}
                                            </>
                                        );
                                    }}
                                />
                            </TCLabel>
                        </Grid>
                    </Grid>
                    <Grid textAlign={"center"}>
                        <TCLabel style={{fontSize: 30}} bold>{order ? currencyFormat(order.total) : ""}</TCLabel>
                    </Grid>
                    <Grid pt={0} pb={0} justifyContent={"center"} textAlign={"center"} onClick={() => {
                        if (expiredQR) { return; }
                        setShowImageDetail((prev) => ({
                            ...prev,
                            open: true,
                            image: barcode
                        }))
                    }}>
                        {
                           !(expiredQR) &&
                            <img width={200} height={282} src={barcode}/>
                        }
                        {
                            (expiredQR) &&
                            <Grid p={4} pb={8}>
                                <img src={padlock} width={100} height={100}/>
                                <Grid sx={{mt: -1}}>
                                    <TCLabel style={{fontSize: 16}}>QR Session expires</TCLabel>   
                                    {/* Do not make payment after QR Session ends. Book again (link ke court booking)*/}
                                    <div style={{
                                        backgroundColor: 'rgba(227, 84, 128, 0.2)', 
                                        border: `solid 1px ${color.pink}`,
                                        borderRadius: 8,
                                        marginTop: 8
                                    }}>
                                        <Grid container p={0.5} spacing={0.5} alignItems={"center"}>
                                            <Grid item xs={1.2}>
                                                <Icon style={{
                                                    color: color.pink,
                                                    paddingTop: 2
                                                }}>warning</Icon>
                                            </Grid>
                                            <Grid item xs textAlign={"left"}>
                                                <TCLabel >Do not make payment after QR Session expires. 
                                                </TCLabel>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                    {
                        order &&
                        <OrderInformation/>
                    }
                </Grid>
            }
            </Grid>
        </Grid>
            {/* Choose payment type */}
            {
                (order && (order.isPaid || order.lastStatus == "failed")) &&
                <Grid sx={{pt: 1}}>
                    <OrderInformation/>
                </Grid>
            }
            {
                loading &&
                <Loading/>
            }
            <SlideAlert
                isOpen={showQR.open || showImageDetail.open} 
                setIsOpen={() => {
                    if (showQR.open) {
                        setShowQR(prev => ({
                            ...prev,
                            open: false
                        }))
                    }
                    if (showImageDetail.open) {
                        setShowImageDetail(prev => ({
                            ...prev,
                            open: false,
                            image: null
                        }))
                    }
                }}
                maxWidth={"xs"}
            >
                {
                    showQR.open &&
                    <QRPage courtInfo={showQR.courtInfo} qrValue={showQR.uniqueCode} orderInfo={showQR.data} />
                }
                {
                    showImageDetail.open &&
                    <img width={"100%"} height={"auto"} src={showImageDetail.image}/>
                }
            </SlideAlert>
        </>
    )
}

function PackageItemView ({
    order
}) {
    return (
        <>
            <Grid sx={{p: 2}} direction={"column"}>
                <div style={{width: 'fit-content'}}>
                    <TCLabel>Booking ID </TCLabel>
                    <div style={{backgroundColor: color.blueIndoor_2, width: '100%', height: '1px'}}/>
                </div>
                <Grid container>
                    <Grid item>
                        <TCTitleLabel>{order.bookingId}</TCTitleLabel>
                    </Grid>
                    <Grid item pt={0.5} pl={1}>
                        <Box>
                            {
                                (order && (order.isPaid || order.lastStatus == "failed")) &&
                                <div className='custom-body' style={{
                                    padding: '1px 5px',
                                    borderRadius: 5,
                                    color: order.isPaid ? 'white' : order.lastStatus != "failed" ? 'white' : 'white',
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    width: 'fit-content',
                                    backgroundColor: order.isPaid ? color.green : order.lastStatus != "failed" ? color.orange : color.pink}}>
                                    {orderStatusMap(order)}
                                </div> 
                            }
                        </Box>
                    </Grid>
                </Grid>
                <Grid>
                    {
                        order.packages &&
                        <Card>
                            <Grid sx={{p: 0}}>
                                <div className='custom-body' style={{padding: 10, paddingBottom: 0}}>
                                    <TCLabel style={{fontSize: 12}}>{order.packages[0].packageName} : <span style={{fontWeight: 'bold'}}>{order.packages[0].packageSession}</span> Sessions</TCLabel>
                                </div>
                                <div className='custom-body' style={{padding: 10, paddingTop: 0}}>
                                    <Grid container spacing={2}>
                                        <Grid item textAlign={"center"}>
                                            <TCLabel style={{fontSize: 12, fontWeight: 'bold' }}>All Court</TCLabel>
                                            <TCLabel style={{fontSize: 12}}>{order.packages[0].packageId && order.packages[0].packageId.court == "all_court" ? order.packages[0].packageSession : 0}</TCLabel>
                                        </Grid>
                                        <Grid item textAlign={"center"}>
                                            <TCLabel style={{fontSize: 12, fontWeight: 'bold' }}>Outdoor</TCLabel>
                                            <TCLabel style={{fontSize: 12}}>{order.packages[0].packageId && order.packages[0].packageId.court == "outdoor" ? order.packages[0].packageSession : 0}</TCLabel>
                                        </Grid>
                                        <Grid item textAlign={"center"}>
                                            <TCLabel style={{fontSize: 12, fontWeight: 'bold' }}>Hitting Room</TCLabel>
                                            <TCLabel style={{fontSize: 12}}>{order.packages[0].packageId && order.packages[0].packageId.court == "hitting_room" ? order.packages[0].packageSession : 0}</TCLabel>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                        </Card>
                    }
                </Grid>
                <Grid sx={{pt: 2}} justifyContent={"center"} textAlign={"center"}>
                    <Card sx={{
                        zIndex: 2,
                        p: 1,
                        mr: 2, ml: 2,
                        backgroundColor: color.blueIndoor_2
                    }}>
                    </Card>
                        <Grid sx={{
                            border: '1px solid #0198E1',
                            borderRadius: 2,
                            p: 1,
                            mt: -1,
                        }} container direction={"column"} justifyContent={"left"} textAlign={"left"}>
                            <Grid sx={{
                                mt: 1
                            }}  container direction={"row"}>
                                <Grid item width={"20%"}>
                                    <TCLabel>Subtotal</TCLabel>
                                </Grid>
                                <Grid item width={'80%'} textAlign={"right"}>
                                    <TCLabel>{order ? currencyFormat(order.subtotal) : 0}</TCLabel>
                                </Grid>
                            </Grid>
                            {
                                (order && order.promoCode) &&
                                <Grid container direction={"row"}>
                                    <Grid item width={"60%"}>
                                        <TCLabel>Discount <span style={{fontSize: 10}}>({order.promoCode.promoCode})</span></TCLabel>
                                    </Grid>
                                    <Grid item width={'40%'} textAlign={"right"}>
                                        <TCLabel style={{
                                            fontWeight: 'bold',
                                            color: color.pink
                                        }}> {order ? `- ${currencyFormat(
                                            order.promoCode.promoCodeType == 'nominal' ? order.promoCode.amount : (order.promoCode.amount / 100) * order.subtotal
                                        )}` : 0}</TCLabel>
                                    </Grid>
                                </Grid>
                            }
                            {
                                (order && order.totalSessionRedeemed) &&
                                <Grid container direction={"row"}>
                                    <Grid item width={"60%"}>
                                        <TCLabel>Value Pack {order.totalSessions} Sessions</TCLabel>
                                    </Grid>
                                    <Grid item width={'40%'} textAlign={"right"}>
                                        <TCLabel>{currencyFormat(order.subtotal - order.total)}</TCLabel>
                                    </Grid>
                                </Grid>
                            }
                            {/* {
                                (order && order.paymentType == "Value Pack") &&
                                <Grid container direction={"row"}>
                                    <Grid item width={"60%"}>
                                        <TCLabel>Value Pack</TCLabel>
                                    </Grid>
                                    <Grid item width={'40%'} textAlign={"right"}>
                                        <TCLabel> {order.totalSessions} Sessions</TCLabel>
                                    </Grid>
                                </Grid>
                            } */}
                            {/* <Grid item width={'100%'} justifyContent={"flex-end"}>
                                {
                                    (order && order.paymentType == "Value Pack") &&
                                    <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                                        <TCLabel style={{
                                            paddingTop: 3,
                                            fontSize: 12,
                                            color: color.grey,
                                            textDecorationLine: 'line-through'
                                        }}> {order ? currencyFormat(order.subtotal) : 0}</TCLabel>
                                    </div>
                                }
                            </Grid> */}
                            {
                                (order && order.uniqueNumber > 0) &&
                                <UniqueCode order={order} />
                            }
                            <div className="separation-line"/>
                            <Grid container direction={"row"}>
                                <Grid item width={"20%"} textAlign={"left"}>
                                    <TCLabel>Total</TCLabel>
                                </Grid>
                                <Grid item width={'80%'} textAlign={"right"}>
                                    <TCLabel style={{
                                        fontWeight: 'bold'
                                    }}> {order ? order.paymentType == "Value Pack" ? 'IDR 0' : currencyFormat(order.total) : 0}</TCLabel>
                                </Grid>
                            </Grid>
                        </Grid>
                    {/* </Card> */}
                </Grid>
            </Grid>
        </>
    )
}

function BookingView ({
    order, setShowQR
}) {
    const placeInfo = null
    return (
        <>
            <Grid sx={{p: 2}} direction={"column"}>
                <div style={{width: 'fit-content'}}>
                    <TCLabel>Booking ID </TCLabel>
                    <div style={{backgroundColor: color.blueIndoor_2, width: '100%', height: '1px'}}/>
                </div>
                <Grid container>
                    <Grid item>
                        <TCTitleLabel>{order.bookingId}</TCTitleLabel>
                    </Grid>
                    <Grid item pt={0.5} pl={1}>
                        <Box>
                            {
                                (order && (order.isPaid || order.lastStatus == "failed")) &&
                                <div className='custom-body' style={{
                                    padding: '1px 5px',
                                    borderRadius: 5,
                                    color: order.isPaid ? 'white' : order.lastStatus != "failed" ? 'white' : 'white',
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    width: 'fit-content',
                                    backgroundColor: order.isPaid ? color.green : order.lastStatus != "failed" ? color.orange : color.pink}}>
                                    {orderStatusMap(order)}
                                    {/* {order.isPaid ? "Paid" : order.lastStatus != "failed" ? "Waiting Payment" : orderStatus.failed} */}
                                </div> 
                            }
                        </Box>
                    </Grid>
                </Grid>
                <div style={{paddingTop: 8, width: 'fit-content'}}>
                    <TCLabel>Schedule Detail</TCLabel>
                    <div style={{backgroundColor: color.blueIndoor_2, width: '100%', height: '1px'}}/>
                </div>
                {
                    order && order.newBookings.map( value => {
                        return (
                            <Grid key={value.date} xs={12} md={12} lg={12} item style={{textAlign: 'left', marginTop: 5}}>
                                <ScheduleCard order={order} value={value} setShowQR={setShowQR}/>
                            </Grid>
                        )
                    })
                }
                <Grid sx={{pt: 2}} justifyContent={"center"} textAlign={"center"}>
                    <Card sx={{
                        zIndex: 2,
                        p: 1,
                        mr: 2, ml: 2,
                        backgroundColor: color.blueIndoor_2
                    }}>
                        {/* <TCTitleLabel style={{fontSize: 15, color: color.blue}}>Your Transaction Summary</TCTitleLabel> */}
                    </Card>
                    {/* show and hide qrcode */}
                    {/* <Card sx={{
                        p: 1, mt: -2
                    }}> */}
                        <Grid sx={{
                            border: '1px solid #0198E1',
                            borderRadius: 2,
                            p: 1,
                            mt: -1,
                        }} container direction={"column"} justifyContent={"left"} textAlign={"left"}>
                            <Grid sx={{
                                mt: 1
                            }}  container direction={"row"}>
                                <Grid item width={"20%"}>
                                    <TCLabel>Subtotal</TCLabel>
                                </Grid>
                                <Grid item width={'80%'} textAlign={"right"}>
                                    <TCLabel>{order ? currencyFormat(order.subtotal) : 0}</TCLabel>
                                </Grid>
                            </Grid>
                            {
                                (order && order.promoCode) &&
                                <Grid container direction={"row"}>
                                    <Grid item width={"60%"}>
                                        <TCLabel>Discount <span style={{fontSize: 10}}>({order.promoCode.promoCode})</span></TCLabel>
                                    </Grid>
                                    <Grid item width={'40%'} textAlign={"right"}>
                                        <TCLabel style={{
                                            fontWeight: 'bold',
                                            color: color.pink
                                        }}> {order ? `- ${currencyFormat(
                                            order.promoCode.promoCodeType == 'nominal' ? order.promoCode.amount : (order.promoCode.amount / 100) * order.subtotal
                                        )}` : 0}</TCLabel>
                                    </Grid>
                                </Grid>
                            }
                            {
                                (order && (order.sessionRedeemed && order.totalSessionRedeemed > 0)) &&
                                <Grid container direction={"row"}>
                                    <Grid item width={"60%"}>
                                        <TCLabel>Value Pack <span style={{fontSize: 10}}>({order.totalSessionRedeemed} {order.totalSessionRedeemed > 1 ? "sessions" : "session"})</span></TCLabel>
                                    </Grid>
                                    <Grid item width={'40%'} textAlign={"right"}>
                                        <TCLabel style={{
                                            fontWeight: 'bold',
                                            color: color.pink
                                        }}> {order ? `- ${currencyFormat(order.subtotal - (order.total - order.uniqueNumber))}` : 0}</TCLabel>
                                    </Grid>
                                </Grid>
                            }
                            {/* {
                                (order && order.paymentType == "Value Pack") &&
                                <Grid container direction={"row"}>
                                    <Grid item width={"60%"}>
                                        <TCLabel>Value Pack <span style={{fontSize: 10}}>({order.totalSessionRedeemed} sessions)</span></TCLabel>
                                    </Grid>
                                    <Grid item width={'40%'} textAlign={"right"}>
                                        <TCLabel> {order.totalSessions} <span style={{fontSize: 10}}>Sessions</span></TCLabel>
                                        <TCLabel> {order.totalSessions} Sessions</TCLabel>
                                        <TCLabel style={{
                                            fontWeight: 'bold',
                                            color: color.pink
                                        }}> {order ? `- ${currencyFormat(order.subtotal - order.total)}` : 0}</TCLabel>
                                    </Grid>
                                </Grid>
                            } */}
                            {/* <Grid item width={'100%'} justifyContent={"flex-end"}>
                                {
                                    (order && order.paymentType == "Value Pack") &&
                                    <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                                        <TCLabel style={{
                                            paddingTop: 3,
                                            fontSize: 12,
                                            color: color.grey,
                                            textDecorationLine: 'line-through'
                                        }}> {order ? currencyFormat(order.subtotal) : 0}</TCLabel>
                                    </div>
                                }
                            </Grid> */}
                            {
                                (order && order.uniqueNumber > 0) &&
                                <UniqueCode order={order}/>
                            }
                            <div className="separation-line"/>
                            <Grid container direction={"row"}>
                                <Grid item width={"20%"} textAlign={"left"}>
                                    <TCLabel>Total</TCLabel>
                                </Grid>
                                <Grid item width={'80%'} textAlign={"right"}>
                                    <TCLabel style={{
                                        fontWeight: 'bold'
                                    }}> {order ? order.paymentType == "Value Pack" ? 'IDR 0' : currencyFormat(order.total) : 0}</TCLabel>
                                </Grid>
                            </Grid>
                        </Grid>
                    {/* </Card> */}
                </Grid>
            </Grid>
        </>
    )
}

function ItemOrderView ({
    order, setShowQR
}) {

    return (
        <>
            <Grid sx={{p: 2}} direction={"column"}>
                <div style={{width: 'fit-content'}}>
                    <TCLabel>Booking ID </TCLabel>
                    <div style={{backgroundColor: color.blueIndoor_2, width: '100%', height: '1px'}}/>
                </div>
                <Grid container>
                    <Grid item>
                        <TCTitleLabel>{order.bookingId}</TCTitleLabel>
                    </Grid>
                    <Grid item pt={0.5} pl={1}>
                        <Box>
                            {
                                order &&
                                <div className='custom-body' style={{
                                    padding: '1px 5px',
                                    borderRadius: 5,
                                    color: order.isPaid ? 'white' : order.lastStatus != "failed" ? 'white' : 'white',
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    width: 'fit-content',
                                    backgroundColor: order.isPaid ? color.green : order.lastStatus != "failed" ? color.orange : color.pink}}>
                                    {orderStatusMap(order)}
                                    {/* {order.isPaid ? "Paid" : order.lastStatus != "failed" ? "Waiting Payment" : orderStatus.failed} */}
                                </div> 
                            }
                        </Box>
                    </Grid>
                </Grid>
                <div style={{paddingTop: 8, width: 'fit-content'}}>
                    <TCLabel>{order.classObj && order.classObj.hours.length > 0 ? `Schedule Detail` : "Product List"}</TCLabel>
                    <div style={{backgroundColor: color.blueIndoor_2, width: '100%', height: '1px'}}/>
                </div>
                {
                    order && order.productOrder.map( value => {
                        return (
                            <Grid key={value.date} xs={12} md={12} lg={12} item style={{textAlign: 'left', marginTop: 5}}>
                                <ProductCard orderType={order.orderType} productOrder={value} hours={value.hours}/>
                            </Grid>
                        )
                    })
                }
                {
                    (order && order.classObj && order.classObj.hours.length > 0) &&
                    <Card style={{marginTop: 5}}>
                        <div className='custom-body' style={{padding: 10, paddingBottom: 0}}>
                            <TCLabel style={{fontWeight: 'bold' }}>{moment(order.classObj.date).format("ddd DD/MM/YYYY")}</TCLabel>
                        </div>
                        <div className='custom-body' style={{display: 'flex', alignItems: "top", padding: 10, paddingBottom: 0, paddingTop: 0}}>
                            <div style={{width: '100%'}}>
                                <TCLabel style={{fontSize: 12, fontWeight: 'bold' }}>{order.classObj.courtInfo && `${courtTypeObj[order.classObj.courtInfo.courtType]} ${order.classObj.courtInfo.name}`} : {order.classObj.name}</TCLabel>
                                <Grid container direction={'row'}>
                                {
                                    order.classObj.hours && order.classObj.hours.map((h, index) => {
                                        return <TCLabel style={{marginRight: 3, fontSize: 12}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)} 
                                            ${index < (order.classObj.hours.length - 1) ? " | " : ""}`}</TCLabel>
                                    })
                                }
                                </Grid>
                                <Grid pb={1}>
                                    <TCLabel>Total: <span style={{fontWeight: 'bold' }}>{`${currencyFormat(order.classObj.fee)} x ${order.classObj.pax} Pax`}</span></TCLabel>
                                </Grid>
                            </div>
                            {
                                (order.isPaid && orderStatusMap(order) != "Complete") &&
                                <div>
                                    <Grid className="element element-1" xs={12}
                                        sx={{
                                            color: order.isPaid ? '#ffff' : color.grey,
                                            width: 40,
                                            height: 40,
                                            mt: 0.5
                                        }} 
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            if (order.isPaid) {
                                                setShowQR({
                                                    open: true,
                                                    courtInfo: {
                                                        ...order.court,
                                                        ...order.classObj.courtInfo,
                                                        hours: order.classObj.hours,
                                                        date: order.date
                                                    },
                                                    data: {
                                                        ...order,
                                                        eventName: order.classObj.name,
                                                        date: order.classObj.date,
                                                        hours: order.classObj.hours,
                                                    },
                                                    uniqueCode: order.uniqueCode,
                                                    value: order.uniqueCode
                                                })
                                            } else {
                                                Confirm("", "Make payment to unlock the QR.")
                                            }
                                        }
                                    }>
                                        <Icon fontSize={'large'}>qr_code_2</Icon>
                                    </Grid>
                                </div>
                            }
                        </div>
                        {/* <Grid item pl={1.2} pb={1.5} pt={0} textAlign={"left"}>
                            <TCLabel>Total: <span style={{fontWeight: 'bold' }}>{`${currencyFormat(order.classObj.fee)} x ${order.classObj.pax} Pax`}</span></TCLabel>
                        </Grid> */}
                    </Card>
                }
                <Grid sx={{pt: 2}} justifyContent={"center"} textAlign={"center"}>
                    <Card sx={{
                        zIndex: 2,
                        p: 1,
                        mr: 2, ml: 2,
                        backgroundColor: color.blueIndoor_2
                    }}>
                    </Card>
                        <Grid sx={{
                            border: '1px solid #0198E1',
                            borderRadius: 2,
                            p: 1,
                            mt: -1,
                        }} container direction={"column"} justifyContent={"left"} textAlign={"left"}>
                            <Grid sx={{
                                mt: 1
                            }}  container direction={"row"}>
                                <Grid item width={"20%"}>
                                    <TCLabel>Subtotal</TCLabel>
                                </Grid>
                                <Grid item width={'80%'} textAlign={"right"}>
                                    <TCLabel>{order ? currencyFormat(order.subtotal) : 0}</TCLabel>
                                </Grid>
                            </Grid>
                            {
                                (order && order.promoCode) &&
                                <Grid container direction={"row"}>
                                    <Grid item width={"60%"}>
                                        <TCLabel>Discount <span style={{fontSize: 10}}>({order.promoCode.promoCode})</span></TCLabel>
                                    </Grid>
                                    <Grid item width={'40%'} textAlign={"right"}>
                                        <TCLabel style={{
                                            fontWeight: 'bold',
                                            color: color.pink
                                        }}> {order ? `- ${currencyFormat(
                                            order.promoCode.promoCodeType == 'nominal' ? order.promoCode.amount : (order.promoCode.amount / 100) * order.subtotal
                                        )}` : 0}</TCLabel>
                                    </Grid>
                                </Grid>
                            }
                            {
                                (order && (order.sessionRedeemed && order.sessionRedeemed > 0) && order.paymentType != "Value Pack") &&
                                <Grid container direction={"row"}>
                                    <Grid item width={"60%"}>
                                        <TCLabel>Pay with session {order.totalSessionRedeemed}</TCLabel>
                                    </Grid>
                                    <Grid item width={'40%'} textAlign={"right"}>
                                        <TCLabel style={{
                                            fontWeight: 'bold',
                                            color: color.pink
                                        }}> {order ? `- ${currencyFormat(order.subtotal - order.total)}` : 0}</TCLabel>
                                    </Grid>
                                </Grid>
                            }
                            {
                                (order && order.paymentType == "Value Pack") &&
                                <Grid container direction={"row"}>
                                    <Grid item width={"60%"}>
                                        <TCLabel>Pay with session</TCLabel>
                                    </Grid>
                                    <Grid item width={'40%'} textAlign={"right"}>
                                        <TCLabel> {order.totalSessions} Sessions</TCLabel>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item width={'100%'} justifyContent={"flex-end"}>
                                {
                                    (order && order.paymentType == "Value Pack") &&
                                    <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                                        <TCLabel style={{
                                            paddingTop: 3,
                                            fontSize: 12,
                                            color: color.grey,
                                            textDecorationLine: 'line-through'
                                        }}> {order ? currencyFormat(order.subtotal) : 0}</TCLabel>
                                    </div>
                                }
                            </Grid>
                            {
                                (order && order.uniqueNumber > 0) &&
                                <UniqueCode order={order} />
                            }
                            <div className="separation-line"/>
                            <Grid container direction={"row"}>
                                <Grid item width={"20%"} textAlign={"left"}>
                                    <TCLabel>Total</TCLabel>
                                </Grid>
                                <Grid item width={'80%'} textAlign={"right"}>
                                    <TCLabel style={{
                                        fontWeight: 'bold'
                                    }}> {order ? order.paymentType == "Value Pack" ? 'IDR 0' : currencyFormat(order.total) : 0}</TCLabel>
                                </Grid>
                            </Grid>
                        </Grid>
                    {/* </Card> */}
                </Grid>
            </Grid>
        </>
    )
}

function ScheduleCard ({
    order,
    value,
    setShowQR
}) {
    return (
        <Card>
            <div className='custom-body' style={{padding: 10}}>
                <TCLabel style={{fontSize: 13, fontWeight: 'bold'}}>{moment(value.date).format("ddd DD/MM/YYYY")}</TCLabel>
                {
                    value.data.map( (v, index) => {
                        return(
                            <>
                                <div style={{display: 'flex', alignItems: 'top', marginTop: 2}}>
                                    <div style={{width: '100%'}}>
                                        <TCLabel style={{fontSize: 12, fontWeight: 'bold' }}>{courtTypeObj[v.court.courtType]} {v.court.name}</TCLabel>
                                        <Grid container>
                                        {
                                            v.hours.map((h, index) => {
                                                return <Grid item>
                                                    <TCLabel style={{fontSize: 12}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)}`}{index < (v.hours.length - 1) ? <span style={{padding: 3, fontFamily: 'roboto', fontWeight: 50}}>{"|"}</span> : ""}
                                                    {/* ${index < (v.hours.length - 1) ? " | " : ""} */}
                                                    </TCLabel>
                                                </Grid>
                                            })
                                        }
                                        </Grid>
                                        <TCLabel>Total: <span style={{fontWeight: 'bold' }}>{currencyFormat(v.total)}</span></TCLabel>
                                    </div>
                                    <div>
                                    {
                                        order.isPaid && 
                                        <Grid className="element element-1" xs={12}
                                            sx={{
                                                color: order.isPaid ? '#ffff' : color.grey,
                                                width: 40,
                                                height: 40,
                                                mt: 0.5
                                            }} 
                                            onClick={(e) => {
                                                e.stopPropagation()
                                                if (order.isPaid) {
                                                    setShowQR({
                                                        open: true,
                                                        courtInfo: {
                                                            ...v.court,
                                                            hours: v.hours,
                                                            date: value.date
                                                        },
                                                        data: v,
                                                        uniqueCode: v.uniqueCode
                                                    })
                                                } else {
                                                    Confirm("", "Make payment to unlock the QR.")
                                                }
                                            }
                                        }>
                                            <Icon fontSize={'large'}>qr_code_2</Icon>
                                        </Grid>
                                    }
                                    </div>
                                </div>
                                { 
                                    (index < (value.data.length - 1)) && 
                                    <div className="separation-line"/>
                                }
                            </>
                        )
                    })
                }
            </div>
        </Card>
    )
}

function ProductCard ({
    orderType,
    productOrder,
    hours
}) {
    return (
        <Card key={productOrder._id}>
            <div className='custom-body' style={{padding: 10}}>
                <Grid container p={1}>
                    <Grid item xs={4} pr={1}>
                        <img src={productOrder.productInfo.image} width={"100%"} height={"auto"}/>
                    </Grid>
                    <Grid item xs={8} textAlign={"left"}>
                        <TCLabel style={{color: color.blueIndoor_2}} bold>
                            {
                                orderType == "rent" ? "Rent" :
                                orderType == "sell" ? "Item" :
                                orderType == "service" ? productOrder.productInfo.category : "Rent"
                            }
                        </TCLabel>
                        <TCLabel bold>{productOrder.productInfo.name}</TCLabel>
                        <TCLabel style={{fontSize: 12, fontWeight: 'bold'}}>{`${moment(productOrder.date).format("ddd DD/MM/YYYY")} - ${courtTypeObj[productOrder.court.courtType]} ${productOrder.court.name}`}</TCLabel>
                        <Grid container>
                        {
                            hours.map( (v, index) => {
                                return <Grid item>
                                        <TCLabel style={{fontSize: 12}}>{`${v} - ${timeStringFormat(Number(v.split(':')[0]) + 1)}`}{index < (hours.length - 1) ? <span style={{padding: 3, fontFamily: 'roboto', fontWeight: 50}}>{"|"}</span> : ""}
                                        {/* ${index < (v.hours.length - 1) ? " | " : ""} */}
                                        </TCLabel>
                                    </Grid>
                            })
                        }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} textAlign={"right"}>
                        <TCLabel bold>{`${currencyFormat(productOrder.productInfo.price)} x ${productOrder.hours.length} ${productOrder.hours.length > 1 ? "Sessions" : "Session"}`}</TCLabel>
                    </Grid>
                </Grid>
            </div>
        </Card>
    )
}

function UniqueCode ({
    order
}) {
    return (
        <Grid container direction={"row"}>
            <Grid item width={"45%"} textAlign={"left"}>
                <TCLabel>Unique Code</TCLabel>
            </Grid>
            <Grid item width={'55%'} textAlign={"right"}>
                <TCLabel>{order.uniqueNumber || 0}</TCLabel>
            </Grid>
        </Grid>
    )
}

export default CounterPayment