import React, { useEffect, useState } from "react";
import { TCLabel, TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import TimeTable from "react-timetable-events";
import moment from "moment";
import { Grid, Table } from "@mui/material";

import { TCInput } from "components/TopCourtComponents/Input/TopCourtInput";
import { toast } from "react-toastify";

// import "./styles.module.css";
// import customClassNames from "./styles.module.css";
import { loadBookingService } from "service/counterService";
import SlideAlert from "components/SlideInAlert/SlideAlert";
import { m } from "framer-motion";
import { color } from "helper/constant";

function CounterTimeTable ({
  nextDay,
  openRentMenu
}) {

  const [ selectedDate, setSelectedDate ] = useState(moment(new Date()).format("YYYY-MM-DD"))
  const [ indoor1Data, setIndoor1Data ] = useState([])
  const [ indoor2Data, setIndoor2Data ] = useState([])
  const [ indoor3Data, setIndoor3Data ] = useState([])
  const [ indoor4Data, setIndoor4Data ] = useState([])
  const [ outdoor5Data, setOutdoor5Data ] = useState([])
  const [ outdoor6Data, setOutdoor6Data ] = useState([])
  const [ hitting1Data, setHitting1Data ] = useState([])

  const [ openAlert, setOpenAlert ] = useState(false)

  // load Today schedule
  useEffect(() => {
    // loadTimeTable()
  }, [selectedDate])

  useEffect(() => {
    loadTimeTable()
  }, [nextDay])

  const timeStringFormat = (e) => {
    let value = e
    return `${value < 10 ? `0${value}` : value}:00`
}
  
  const loadTimeTable = async () => {
    let date = moment(new Date()).format('YYYY-MM-DD')
    if (nextDay) { date = moment(new Date()).add(1, 'day').format('YYYY-MM-DD') }

    let { data, error } = await loadBookingService(date)

    let indoor1Data = []
    let indoor2Data = []
    let indoor3Data = []
    let indoor4Data = []

    let outDoor5Data = []
    let outDoor6Data = []

    let hittingRoomData = []

    // bookingList, service
    if (!data) { return; }

    for (let i = 0; i < data.length; i ++) {
      if (`${data[i].court.courtType} ${data[i].court.name}`
          == "indoor 1"
      ) {
        // check hours
        let hoursList = loopHours(data[i])
        indoor1Data = indoor1Data.concat(hoursList)
      }
      if (`${data[i].court.courtType} ${data[i].court.name}`
          == "indoor 2"
      ) {
        // check hours
        let hoursList = loopHours(data[i])
        indoor2Data = indoor2Data.concat(hoursList)
      }
      if (`${data[i].court.courtType} ${data[i].court.name}`
          == "indoor 3"
      ) {
        // check hours
        console.log(data[i])
        let hoursList = loopHours(data[i])
        indoor3Data = indoor3Data.concat(hoursList)
      }
      if (`${data[i].court.courtType} ${data[i].court.name}`
          == "indoor 4"
      ) {
        // check hours
        let hoursList = loopHours(data[i])
        indoor4Data = indoor4Data.concat(hoursList)
      }
      if (`${data[i].court.courtType} ${data[i].court.name}`
          == "outdoor 5"
      ) {
        // check hours
        let hoursList = loopHours(data[i])
        outDoor5Data = outDoor5Data.concat(hoursList)
      }
      if (`${data[i].court.courtType} ${data[i].court.name}`
          == "outdoor 6"
      ) {
        // check hours
        let hoursList = loopHours(data[i])
        outDoor6Data = outDoor6Data.concat(hoursList)
      }
      if (`${data[i].court.courtType} ${data[i].court.name}`
          == "hitting_room H1"
      ) {
        // check hours
        let hoursList = loopHours(data[i])
        hittingRoomData = hittingRoomData.concat(hoursList)
      }
      setIndoor1Data(indoor1Data)
      setIndoor2Data(indoor2Data)
      setIndoor3Data(indoor3Data)
      setIndoor4Data(indoor4Data)
      setOutdoor5Data(outDoor5Data)
      setOutdoor6Data(outDoor6Data)
      setHitting1Data(hittingRoomData)
    }
  }

  const loopHours = (data) => {
    let dataList = []
    let currentStartHours = data.hours[0]
    if (data.hours.length > 1) {
      for (let j = 0; j < data.hours.length; j ++) {
        dataList.push({
          ...data,
          name: `${data.order ? data.order.user ? data.order.user.name : '' : ''}`,
          // type: "custom",
          startHour: data.hours[j]
        })
        currentStartHours = data.hours[j]
        console.log(dataList)
      }
    } else {
      dataList.push({
        ...data,
        name: `${data.order ? data.order.user ? data.order.user.name : '' : ''}`,
        // type: "custom",
        startHour: data.hours[0]
      })
    }
    return dataList
  }

  const events = {
      events: {
        "indoor 1": indoor1Data,
        "indoor 2": indoor2Data,
        "indoor 3": indoor3Data,
        "indoor 4": indoor4Data,
        "outdoor 5": outdoor5Data,
        "outdoor 6": outdoor6Data,
        "hitting h1": hitting1Data,
      },
  };

  const titleEvent = [
    "Indoor 1", 
    "Indoor 2",
    "Indoor 3",
    "Indoor 4",
    "Outdoor 5",
    "Outdoor 6",
    "Hitting H1"
  ]

  return (
      <div>
        <TimeTableComponent nextDay={nextDay} data={{
          "indoor_1": indoor1Data,
          "indoor_2": indoor2Data,
          "indoor_3": indoor3Data,
          "indoor_4": indoor4Data,
          "outdoor_5": outdoor5Data,
          "outdoor_6": outdoor6Data,
          "hitting_h1": hitting1Data,
        }} openRentMenu={openRentMenu} />
        {/* <FakeTimeTableComponent /> */}
        <SlideAlert
          isOpen={openAlert}
          setisOpen={setOpenAlert}
        >

        </SlideAlert>
      </div>
  )
}

function TimeTableComponent ({
  nextDay,
  data,
  openRentMenu
}) {

  const hours = [
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    // "23:00",
  ]

  const [ showAlert, setShowAlert ] = useState({
    open: false,
    data: null,
    rallyPartner: null, 
    ballBoy: null, 
    rent: null, 
    rentOut: null, 
    returnRent: null, 
    classParticipant: null,
    lateRent: null,
    hours: null
  })

  console.log('here data', data)

  const height = 100

  const hoursData = []

  const isCoach = (value) => {
    let coach = value.productInfo.category == "Development Coach" || value.productInfo.category == "Lead Coach" || value.productInfo.category == "Senior Coach" || value.productInfo.category == "Head Coach"
    return coach
  }

  const mappingHours = (hours, court) => {
    console.log('here check data', hours, court)
    for (let i = 0; i < court.length; i ++) {
      // if (court[i].hours.indexOf(hours) >= 0) {
      if (court[i].startHour === hours) {
        var filterRP = court[i].service.filter(function (value) {
          return value.productInfo.category == "Rally Partner" && value.hours.indexOf(hours) >= 0;
        });
        var filterBB = court[i].service.filter(function (value) {
          return value.productInfo.category == "Ball Boy" && value.hours.indexOf(hours) >= 0;
        });
        var filterCoach = court[i].service.filter(function (value) {
          return isCoach(value) && value.hours.indexOf(hours) >= 0;
        });
        var filterRent = court[i].service.filter(function (value) {
          return (value.productInfo.category != "Ball Boy" && value.productInfo.category != "Rally Partner" && !isCoach(value) && value.status == "paid") && value.hours.indexOf(hours) >= 0;
        });
        var filterRentOut = court[i].service.filter(function (value) {
          return (value.productInfo.category != "Ball Boy" && value.productInfo.category != "Rally Partner" && !isCoach(value) && value.status == "rent") && value.hours.indexOf(hours) >= 0;
        });
        var lateRent = court[i].service.filter(function (value) {
          console.log('here late', value.date)
          console.log('here orderFinish', moment(value.date), moment(new Date()))
          if (moment(value.date) < moment(new Date())) {
            if (moment(value.date).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD")) {
                let endHoursFormat = value.hours[value.hours.length - 1].split(':')[0]
                endHoursFormat = Number(endHoursFormat) + 1
                endHoursFormat = endHoursFormat > 9 ? `${endHoursFormat}:00` : `0${endHoursFormat}:00`
                let ordersFinish = new Date(`${value.date} ${endHoursFormat}`)
                console.log('here orderFinish', ordersFinish)
                if (moment(ordersFinish) < moment(new Date())) {
                  return (value.productInfo.category != "Ball Boy" && value.productInfo.category != "Rally Partner" && value.status == "rent") && value.hours.indexOf(hours) >= 0; 
                }
            } else {
              return (value.productInfo.category != "Ball Boy" && value.productInfo.category != "Rally Partner" && value.status == "rent") && value.hours.indexOf(hours) >= 0; 
            }
          } else {
            let endHoursFormat = value.hours[value.hours.length - 1].split(':')[0]
            endHoursFormat = Number(endHoursFormat) + 1
            endHoursFormat = endHoursFormat > 9 ? `${endHoursFormat}:00` : `0${endHoursFormat}:00`
            let ordersFinish = new Date(`${value.date} ${endHoursFormat}`)
            if (moment(ordersFinish) < moment(new Date())) {
              return (value.productInfo.category != "Ball Boy" && value.productInfo.category != "Rally Partner" && value.status == "rent") && value.hours.indexOf(hours) >= 0; 
            }
          }
        });
        var filterReturn = court[i].service.filter(function (value) {
          return (value.productInfo.category != "Ball Boy" && value.productInfo.category != "Rally Partner" && value.status == "complete") && value.hours.indexOf(hours) >= 0;
        });
        let rallyPartner = filterRP.map(value => {
          if (value.productInfo.category == "Rally Partner") {
            return value.productInfo.name
          }
        })
        let ballBoy = filterBB.map(value => {
          if (value.productInfo.category == "Ball Boy") {
            return value.productInfo.name
          }
        })
        let coach = filterCoach.map(value => {
          if (isCoach(value)) {
            return value.productInfo.name
          }
        })
        let rent = filterRent.map(value => {
          return value.productInfo.name
        })
        let rentOut = filterRentOut.map(value => {
          return value.productInfo.name
        })
        let returnRent = filterReturn.map(value => {
          return value.productInfo.name
        })
        let classParticipant = court[i].participant ? court[i].participant.map(value => {
          return value
        }) : []
        let coachInfo = filterCoach.map(value => {
          if (isCoach(value)) {
            return {
              _id: value.item,
              name: value.productInfo.name
            }
          }
        })
        let selectedCoachParticipant = []
        if (court[i].isClass) {
          for (let j = 0; j < coachInfo.length; j ++) {
            let coachList = []
            for (let k = 0; k < court[i].participant.length; k ++) {
              let participants = court[i].participant[k]
              if (participants.service && participants.service.length >= coachInfo.length) { continue; }
              if (participants.service && participants.service.indexOf(coachInfo[j]._id) >= 0) {
                coachList.push(participants)
                selectedCoachParticipant.push(participants._id)
              }
            }
            coachInfo[j].coachingList = coachList
          }
        }
        return <Grid xs={12} p={1} sx={{background: (court[i].isTournament == true || court[i].isClass == true || court[i].classInfo) ? "#E35480" : lateRent.length > 0 ? "#FF0000" : rentOut.length > 0 ? "#f79256" : "#0198E1", height: 100,
          width: '100%'
        }} onClick={() => {
          // if (rent.length > 0 || rentOut.length > 0 ||returnRent.length > 0) {
          //   // openRentMenu()
          // } else if (court[i].classInfo) {
          //   setShowAlert({
          //     open: true,
          //     data: court[i],
          //     rallyPartner, ballBoy, rent, rentOut, returnRent, classParticipant
          //   })
          // }
          setShowAlert({
            open: true,
            data: court[i],
            hours: hours,
            rallyPartner, lateRent, ballBoy, rent, rentOut, returnRent, classParticipant, coach, 
            coachInfo,
            selectedCoachParticipant
          })
        }}>
          {/* <TCLabel style={{color: 'white'}}>
            {}
          </TCLabel> */}
          <Grid mb={0.5}>
              <TCLabel style={{fontSize: 10, color: 'white'}} bold>{
                ((court[i].isClass == true || court[i].isTournament == true) && court[i].classInfo) ? court[i].classInfo.name : 
                court[i].eventName ? court[i].eventName : court[i].name
              }</TCLabel>
          </Grid>
          <Grid mb={0.5}>
            <TCLabel bold style={{fontSize: 10, color: 'white'}}>{`${court[i].startHour} - ${(Number(court[i].startHour.split(":")[0]) + 1) > 9 ? `${Number(court[i].startHour.split(":")[0]) + 1}` : `0${Number(court[i].startHour.split(":")[0]) + 1}`}:00`}</TCLabel>
          </Grid>
          {/* {
            (court[i].service && court[i].service.length >= 0) &&
            <Grid>
              {
                court[i].service.map((value, index) => {
                  return (
                    value.productInfo.category == "Rally Partner" &&
                    <Grid container spacing={0.5}>
                      {
                        index == 0 &&
                        <Grid item>
                          <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`${value.productInfo.category == "Rally Partner" ?  "RP" : "BB"}`}</TCLabel>
                        </Grid>
                      }
                      <Grid item>
                        <TCLabel style={{fontSize: 10, color: 'white'}}>{`${value.productInfo.name}`}</TCLabel>
                      </Grid>
                    </Grid>
                  )
                })
              }
            </Grid>
          } */}
          {
            (coach.length > 0 && !court[i].isClass) &&
            <Grid container spacing={0.5}>
              <Grid item>
                <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`Coach:`}</TCLabel>
              </Grid>
              <Grid item>
                <TCLabel style={{fontSize: 10, color: 'white'}}>{`${coach.toString()}`}</TCLabel>
              </Grid>
            </Grid>
          }
          {
            (coach.length > 0 && court[i].isClass) &&
            coachInfo.map( value => {
              return (
                <Grid container spacing={0.5}>
                  <Grid item>
                    <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`Coach:`}</TCLabel>
                  </Grid>
                  <Grid item>
                    <TCLabel style={{fontSize: 10, color: 'white'}}>{`${value.name}`}</TCLabel>
                  </Grid>
                  <Grid item xs={12} mt={-0.5}>
                    {
                      value.coachingList.map( (v, index) => {
                        if (v.hours) { if (v.hours.indexOf(hours) < 0) { return; } }
                        return (
                          <Grid item>
                            <TCLabel style={{fontSize: 10, color: 'white'}}>{`${v.attend ? '✔' : ''} ${v.user.name.toString()} (${v.pax}) ${(index !== value.coachingList.length - 1 ? ',' : '')}`}</TCLabel>
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                </Grid>
              )
            })
          }
          {
            rallyPartner.length > 0 &&
            <Grid container spacing={0.5}>
              <Grid item>
                <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`RP:`}</TCLabel>
              </Grid>
              <Grid item>
                <TCLabel style={{fontSize: 10, color: 'white'}}>{`${rallyPartner.toString()}`}</TCLabel>
              </Grid>
            </Grid>
          }
          {
            ballBoy.length > 0 &&
            <Grid container spacing={0.5}>
              <Grid item>
                <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`BB:`}</TCLabel>
              </Grid>
              <Grid item>
                <TCLabel style={{fontSize: 10, color: 'white'}}>{`${ballBoy.toString()}`}</TCLabel>
              </Grid>
            </Grid>
          }
          {
            rent.length > 0 &&
            <Grid container spacing={0.5}>
              <Grid item>
                <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`Rent:`}</TCLabel>
              </Grid>
              <Grid item>
                <TCLabel style={{fontSize: 10, color: 'white'}}>{`${rent.toString()}`}</TCLabel>
              </Grid>
            </Grid>
          }
          {
            rentOut.length > 0 &&
            <Grid container spacing={0.5}>
              <Grid item>
                <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`Rent Out:`}</TCLabel>
              </Grid>
              <Grid item>
                <TCLabel style={{fontSize: 10, color: 'white'}}>{`${rentOut.toString()}`}</TCLabel>
              </Grid>
            </Grid>
          }
          {
            returnRent.length > 0 &&
            <Grid container spacing={0.5}>
              <Grid item>
                <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`Return:`}</TCLabel>
              </Grid>
              <Grid item>
                <TCLabel style={{fontSize: 10, color: 'white'}}>{`${returnRent.toString()}`}</TCLabel>
              </Grid>
            </Grid>
          }
          {
            classParticipant.length > 0 &&
            <Grid container spacing={0.5}>
              {/* <Grid item>
                <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`Participant:`}</TCLabel>
              </Grid> */}
              <Grid item>
                <div style={{
                  overflowX: 'auto'
                }}>
                {
                  classParticipant.map((v, index) => {
                    if (selectedCoachParticipant.indexOf(v._id) >= 0) { return; }
                    if (v.hours) { if (v.hours.indexOf(hours) < 0) { return; } }
                    return (
                      <Grid item>
                        <TCLabel style={{fontSize: 10, color: 'white'}}>{`${v.attend ? '✔' : ''} ${v.user.name.toString()} (${v.pax}) ${(index !== classParticipant.length - 1 ? ',' : '')}`}</TCLabel>
                      </Grid>
                    )
                  })
                }
                </div>
              </Grid>
            </Grid>
          }
        </Grid>
      }
    }
  }

  return (
    <Grid container>
      <Grid container item xs={0.87}>
        <Grid item xs={12} p={0} sx={{
          fontSize: 15
        }}>Time</Grid>
        {
          hours.map( value => {
            return (
              <Grid item xs={12} height={height} container p={0} sx={{background: !nextDay ? '#025597' : 'grey'}}
                justifyContent={"center"}
                style={{
                  outline: "1px dotted grey",
                  textDecoration: "dotted",
                }}
              >
                <Grid textAlign={"center"} p={0} sx={{marginTop: 5}}>
                  <TCLabel style={{
                    color: 'white',
                    fontSize: 15
                  }} bold>{value}</TCLabel>
                </Grid>
              </Grid>
            )
          })
        }
      </Grid>
      <Grid container item xs={1.59}>
        <Grid item xs={12} p={0} sx={{
          fontSize: 15
        }}>Time</Grid>
        {
          hours.map( value => {
            return (
              <Grid item xs={12} height={height} container p={0} sx={{background: 'white'}}
                justifyContent={"center"}
                style={{
                  outline: "1px dotted grey",
                  textDecoration: "dotted",
                }}
              >
                {
                  mappingHours(value, data.indoor_1)
                }
              </Grid>
            )
          })
        }
      </Grid>
      <Grid container item xs={1.62}>
        <Grid item xs={12} p={0} sx={{
          fontSize: 15
        }}>Time</Grid>
        {
          hours.map( value => {
            return (
              <Grid item xs={12} height={height} container p={0} sx={{background: 'white'}}
                justifyContent={"center"}
                style={{
                  outline: "1px dotted grey",
                  textDecoration: "dotted",
                }}
              >
                {
                  mappingHours(value, data.indoor_2)
                }
              </Grid>
            )
          })
        }
      </Grid>
      <Grid container item xs={1.61}>
        <Grid item xs={12} p={0} sx={{
          fontSize: 15
        }}>Time</Grid>
        {
          hours.map( value => {
            return (
              <Grid item xs={12} height={height} container p={0} sx={{background: 'white'}}
                justifyContent={"center"}
                style={{
                  outline: "1px dotted grey",
                  textDecoration: "dotted",
                }}
              >
                {
                  mappingHours(value, data.indoor_3)
                }
              </Grid>
            )
          })
        }
      </Grid>
      <Grid container item xs={1.60}>
        <Grid item xs={12} p={0} sx={{
          fontSize: 15
        }}>Time</Grid>
        {
          hours.map( value => {
            return (
              <Grid item xs={12} height={height} container p={0} sx={{background: 'white'}}
                justifyContent={"center"}
                style={{
                  outline: "1px dotted grey",
                  textDecoration: "dotted",
                }}
              >
                {
                  mappingHours(value, data.indoor_4)
                }
              </Grid>
            )
          })
        }
      </Grid>
      <Grid container item xs={1.62}>
        <Grid item xs={12} p={0} sx={{
          fontSize: 15
        }}>Time</Grid>
        {
          hours.map( value => {
            return (
              <Grid item xs={12} height={height} container p={0} sx={{background: 'white'}}
                justifyContent={"center"}
                style={{
                  outline: "1px dotted grey",
                  textDecoration: "dotted",
                }}
              >
                {
                  mappingHours(value, data.outdoor_5)
                }
              </Grid>
            )
          })
        }
      </Grid>
      <Grid container item xs={1.60}>
        <Grid item xs={12} p={0} sx={{
          fontSize: 15
        }}>Time</Grid>
        {
          hours.map( value => {
            return (
              <Grid item xs={12} height={height} container p={0} sx={{background: 'white'}}
                justifyContent={"center"}
                style={{
                  outline: "1px dotted grey",
                  textDecoration: "dotted",
                }}
              >
                {
                  mappingHours(value, data.outdoor_6)
                }
              </Grid>
            )
          })
        }
      </Grid>
      <Grid container item xs={1.49}>
        <Grid item xs={12} p={0} sx={{
          fontSize: 15
        }}>Time</Grid>
        {
          hours.map( value => {
            return (
              <Grid item xs={12} height={height} container p={0} sx={{background: 'white'}}
                justifyContent={"center"}
                style={{
                  outline: "1px dotted grey",
                  textDecoration: "dotted",
                }}
              >
                {
                  mappingHours(value, data.hitting_h1)
                }
              </Grid>
            )
          })
        }
      </Grid>
      <SlideAlert 
        isOpen={showAlert.open}
        setIsOpen={(e) => {
            setShowAlert({
              open: false,
              data: null,
              rallyPartner: null, 
              ballBoy: null, 
              rent: null, 
              rentOut: null, 
              returnRent: null, 
              classParticipant: null,
              coach: null,
              hours: null
            })
        }}
        maxWidth={"xs"}
      >
        {
          showAlert.data &&
          <Grid xs={12} p={1} sx={{background: (showAlert.data.isTournament == true || showAlert.data.isClass == true || showAlert.data.classInfo) ? "#E35480" : showAlert.lateRent.length > 0 ? "#FF0000" : showAlert.rentOut.length > 0 ? "#f79256" : "#0198E1",
            width: '100%'
          }}>
            {/* <TCLabel style={{color: 'white'}}>
              {}
            </TCLabel> */}
            <Grid mb={0.5}>
              <TCLabel style={{fontSize: 10, color: 'white'}} bold>{
                ((showAlert.data.isClass == true || showAlert.data.isTournament == true) && showAlert.data.classInfo) ? showAlert.data.classInfo.name : 
                showAlert.data.eventName ? showAlert.data.eventName : showAlert.data.name
              }</TCLabel>
            </Grid>
            <Grid mb={0.5}>
              <TCLabel bold style={{fontSize: 10, color: 'white'}}>{`${showAlert.data.startHour} - ${(Number(showAlert.data.startHour.split(":")[0]) + 1) > 9 ? `${Number(showAlert.data.startHour.split(":")[0]) + 1}` : `0${Number(showAlert.data.startHour.split(":")[0]) + 1}`}:00`}</TCLabel>
            </Grid>
            {/* {
              showAlert.coach.length > 0 &&
              <Grid container spacing={0.5}>
                <Grid item>
                  <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`Coach:`}</TCLabel>
                </Grid>
                <Grid item>
                  <TCLabel style={{fontSize: 10, color: 'white'}}>{`${showAlert.coach.toString()}`}</TCLabel>
                </Grid>
              </Grid>
            } */}
            {
              (showAlert.coach.length > 0 && !showAlert.data.isClass) &&
              <Grid container spacing={0.5}>
                <Grid item>
                  <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`Coach:`}</TCLabel>
                </Grid>
                <Grid item>
                  <TCLabel style={{fontSize: 10, color: 'white'}}>{`${showAlert.coach.toString()}`}</TCLabel>
                </Grid>
              </Grid>
            }
            {
              (showAlert.coach.length > 0 && showAlert.data.isClass) &&
              showAlert.coachInfo.map( value => {
                return (
                  <Grid container spacing={0.5}>
                    <Grid item>
                      <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`Coach:`}</TCLabel>
                    </Grid>
                    <Grid item>
                      <TCLabel style={{fontSize: 10, color: 'white'}}>{`${value.name}`}</TCLabel>
                    </Grid>
                    <Grid item xs={12} mt={-0.5}>
                      {
                        value.coachingList.map( (v, index) => {
                          if (v.hours) { if (v.hours.indexOf(showAlert.hours) < 0) { return; } }
                          return (
                            <Grid item>
                              <TCLabel style={{fontSize: 10, color: 'white'}}>{`${v.attend ? '✔' : ''} ${v.user.name.toString()} (${v.pax}) ${(index !== value.coachingList.length - 1 ? ',' : '')}`}</TCLabel>
                            </Grid>
                          )
                        })
                      }
                    </Grid>
                  </Grid>
                )
              })
            }
            {
              showAlert.rallyPartner.length > 0 &&
              <Grid container spacing={0.5}>
                <Grid item>
                  <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`RP:`}</TCLabel>
                </Grid>
                <Grid item>
                  <TCLabel style={{fontSize: 10, color: 'white'}}>{`${showAlert.rallyPartner.toString()}`}</TCLabel>
                </Grid>
              </Grid>
            }
            {
              showAlert.ballBoy.length > 0 &&
              <Grid container spacing={0.5}>
                <Grid item>
                  <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`BB:`}</TCLabel>
                </Grid>
                <Grid item>
                  <TCLabel style={{fontSize: 10, color: 'white'}}>{`${showAlert.ballBoy.toString()}`}</TCLabel>
                </Grid>
              </Grid>
            }
            {
              showAlert.rent.length > 0 &&
              <Grid container spacing={0.5}>
                <Grid item>
                  <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`Rent:`}</TCLabel>
                </Grid>
                <Grid item>
                  <TCLabel style={{fontSize: 10, color: 'white'}}>{`${showAlert.rent.toString()}`}</TCLabel>
                </Grid>
              </Grid>
            }
            {
              showAlert.rentOut.length > 0 &&
              <Grid container spacing={0.5}>
                <Grid item>
                  <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`Rent Out:`}</TCLabel>
                </Grid>
                <Grid item>
                  <TCLabel style={{fontSize: 10, color: 'white'}}>{`${showAlert.rentOut.toString()}`}</TCLabel>
                </Grid>
              </Grid>
            }
            {
              showAlert.returnRent.length > 0 &&
              <Grid container spacing={0.5}>
                <Grid item>
                  <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`Return:`}</TCLabel>
                </Grid>
                <Grid item>
                  <TCLabel style={{fontSize: 10, color: 'white'}}>{`${showAlert.returnRent.toString()}`}</TCLabel>
                </Grid>
              </Grid>
            }
            {
              showAlert.classParticipant.length > 0 &&
              <Grid container spacing={0.5}>
                {/* <Grid item>
                  <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`Participant:`}</TCLabel>
                </Grid> */}
                {
                  showAlert.classParticipant.map((v, index) => {
                    if (showAlert.selectedCoachParticipant.indexOf(v._id) >= 0) { return; }
                    if (v.hours) { if (v.hours.indexOf(showAlert.hours) < 0) { return; } }
                    return (
                      <Grid item>
                        <TCLabel style={{fontSize: 10, color: 'white'}}>{`${v.attend ? '✔' : ''} ${v.user.name.toString()} (${v.pax}) ${(index !== showAlert.classParticipant.length - 1 ? ',' : '')}`}</TCLabel>
                      </Grid>
                    )
                  })
                }
              </Grid>
            }
          </Grid>
        }
      </SlideAlert>
    </Grid>
  )
}

function FakeTimeTableComponent () {

  const hours = [
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    // "23:00",
  ]

  const height = 100

  const hoursData = []

  const mappingHours = (hours, court) => {
    console.log('here check data', hours, court)
    return <Grid xs={12} p={1} sx={{background: color.pink, height: 100,
      width: '100%'
    }}>
      {/* <TCLabel style={{color: 'white'}}>
        {}
      </TCLabel> */}
      <Grid mb={0.5}>
        <TCLabel style={{fontSize: 10, color: 'white'}} bold>Fun MABAR with Andreybanyu & Dwityanto (1st Tennis Content Creator in Indonesia)</TCLabel>
      </Grid>
      <Grid mb={0.5}>
        <TCLabel bold style={{fontSize: 10, color: 'white'}}>{`${hours} - ${(Number(hours.split(":")[0]) + 1) > 9 ? `${Number(hours.split(":")[0]) + 1}` : `0${Number(hours.split(":")[0]) + 1}`}:00`}</TCLabel>
      </Grid>
      {/* {
        (court[i].service && court[i].service.length >= 0) &&
        <Grid>
          {
            court[i].service.map((value, index) => {
              return (
                value.productInfo.category == "Rally Partner" &&
                <Grid container spacing={0.5}>
                  {
                    index == 0 &&
                    <Grid item>
                      <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`${value.productInfo.category == "Rally Partner" ?  "RP" : "BB"}`}</TCLabel>
                    </Grid>
                  }
                  <Grid item>
                    <TCLabel style={{fontSize: 10, color: 'white'}}>{`${value.productInfo.name}`}</TCLabel>
                  </Grid>
                </Grid>
              )
            })
          }
        </Grid>
      } */}
      {/* {
        rallyPartner.length > 0 &&
        <Grid container spacing={0.5}>
          <Grid item>
            <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`RP:`}</TCLabel>
          </Grid>
          <Grid item>
            <TCLabel style={{fontSize: 10, color: 'white'}}>{`${rallyPartner.toString()}`}</TCLabel>
          </Grid>
        </Grid>
      }
      {
        ballBoy.length > 0 &&
        <Grid container spacing={0.5}>
          <Grid item>
            <TCLabel style={{fontSize: 10, color: 'white'}} bold>{`BB:`}</TCLabel>
          </Grid>
          <Grid item>
            <TCLabel style={{fontSize: 10, color: 'white'}}>{`${ballBoy.toString()}`}</TCLabel>
          </Grid>
        </Grid>
      } */}
    </Grid>
  }

  return (
    <Grid container>
      <Grid container item xs={0.87}>
        <Grid item xs={12} p={0} sx={{
          fontSize: 15
        }}>Time</Grid>
        {
          hours.map( value => {
            return (
              <Grid item xs={12} height={height} container p={0} sx={{background: '#025597'}}
                justifyContent={"center"}
                style={{
                  outline: "1px dotted grey",
                  textDecoration: "dotted",
                }}
              >
                <Grid textAlign={"center"} p={0} sx={{marginTop: 5}}>
                  <TCLabel style={{
                    color: 'white',
                    fontSize: 15
                  }} bold>{value}</TCLabel>
                </Grid>
              </Grid>
            )
          })
        }
      </Grid>
      <Grid container item xs={1.59}>
        <Grid item xs={12} p={0} sx={{
          fontSize: 15
        }}>Time</Grid>
        {
          hours.map( value => {
            return (
              <Grid item xs={12} height={height} container p={0} sx={{background: 'white'}}
                justifyContent={"center"}
                style={{
                  outline: "1px dotted grey",
                  textDecoration: "dotted",
                }}
              >
                {
                  mappingHours(value)
                }
              </Grid>
            )
          })
        }
      </Grid>
      <Grid container item xs={1.59}>
        <Grid item xs={12} p={0} sx={{
          fontSize: 15
        }}>Time</Grid>
        {
          hours.map( value => {
            return (
              <Grid item xs={12} height={height} container p={0} sx={{background: 'white'}}
                justifyContent={"center"}
                style={{
                  outline: "1px dotted grey",
                  textDecoration: "dotted",
                }}
              >
                {
                  mappingHours(value)
                }
              </Grid>
            )
          })
        }
      </Grid>
      <Grid container item xs={1.59}>
        <Grid item xs={12} p={0} sx={{
          fontSize: 15
        }}>Time</Grid>
        {
          hours.map( value => {
            return (
              <Grid item xs={12} height={height} container p={0} sx={{background: 'white'}}
                justifyContent={"center"}
                style={{
                  outline: "1px dotted grey",
                  textDecoration: "dotted",
                }}
              >
                {
                  mappingHours(value)
                }
              </Grid>
            )
          })
        }
      </Grid>
      <Grid container item xs={1.59}>
        <Grid item xs={12} p={0} sx={{
          fontSize: 15
        }}>Time</Grid>
        {
          hours.map( value => {
            return (
              <Grid item xs={12} height={height} container p={0} sx={{background: 'white'}}
                justifyContent={"center"}
                style={{
                  outline: "1px dotted grey",
                  textDecoration: "dotted",
                }}
              >
                {
                  mappingHours(value)
                }
              </Grid>
            )
          })
        }
      </Grid>
      <Grid container item xs={1.59}>
        <Grid item xs={12} p={0} sx={{
          fontSize: 15
        }}>Time</Grid>
        {
          hours.map( value => {
            return (
              <Grid item xs={12} height={height} container p={0} sx={{background: 'white'}}
                justifyContent={"center"}
                style={{
                  outline: "1px dotted grey",
                  textDecoration: "dotted",
                }}
              >
                {
                  mappingHours(value)
                }
              </Grid>
            )
          })
        }
      </Grid>
      <Grid container item xs={1.59}>
        <Grid item xs={12} p={0} sx={{
          fontSize: 15
        }}>Time</Grid>
        {
          hours.map( value => {
            return (
              <Grid item xs={12} height={height} container p={0} sx={{background: 'white'}}
                justifyContent={"center"}
                style={{
                  outline: "1px dotted grey",
                  textDecoration: "dotted",
                }}
              >
                {
                  mappingHours(value)
                }
              </Grid>
            )
          })
        }
      </Grid>
      <Grid container item xs={1.59}>
        <Grid item xs={12} p={0} sx={{
          fontSize: 15
        }}>Time</Grid>
        {
          hours.map( value => {
            return (
              <Grid item xs={12} height={height} container p={0} sx={{background: 'white'}}
                justifyContent={"center"}
                style={{
                  outline: "1px dotted grey",
                  textDecoration: "dotted",
                }}
              >
                {
                  mappingHours(value)
                }
              </Grid>
            )
          })
        }
      </Grid>
    </Grid>
  )
}

export default CounterTimeTable