import { getUserInfo, getCourtInfo, setLocalBookingForm, getLocalBookingForm, deleteLocalBookingForm } from 'helper/localStorage';
import { getCourtInformation, getCourtSchedule, getScheduleCart, 
    addScheduleCart, deleteScheduleCart, submitOrder, setValuePack, getUserCartSummary } from 'service/bookingService';
import moment from 'moment';
import { getMe } from 'service/authService';
import { getCouponPopup, checkCoupon } from 'service/couponService';
import { Confirm } from 'helper/showAlert';

export const loadMe = async (setUser) => {
    try {
        let { data, error } = await getMe()
        if (error) { throw error }
        if (data) { setUser(data) }
    } catch (error) {
        console.log(error)
    }
}

export const getCourtsDetail = async (courtInfo, setCourts, setForm) => {
    try {
        let { data, error } = await getCourtInformation(courtInfo._id)
        if (error) { throw error }
        if (data) { 
            setCourts(data.courts); 
            setForm(prev => ({
                ...prev,
                court: prev.court ? prev.court : data.courts[0]._id 
            }))
            console.log(data) 
        }
    } catch (error) {
        console.log(error)
    }
}

export const getCourtsSchedule = async (court, day, setAvailableHours, setLoading) => {
    try {
        const currentUser = getUserInfo ? JSON.parse(getUserInfo) : null;
        let { data, error } = await getCourtSchedule(court, day, currentUser ? currentUser._id : '')
        if (error) { throw error }
        if (data) { 
            console.log(data) 
            setLoading(false)
            setAvailableHours(data.hours)
        }
    } catch (error) {
        setLoading(false)
        console.log(error)
    }
}

export const getUserCart = async (setBooking, setLoading) => {
    try {
        let courtInfo = getCourtInfo ?  JSON.parse(getCourtInfo) : null
        let { data, error } = await getScheduleCart({
            placeId: courtInfo._id
        });
        setLoading(false)
        if ( error ) { throw error }
        if ( data ) { setBooking(data) }
    } catch (error) {
        setLoading(false)
        console.log(error)
    }
}

export const addCart = async(form, setBooking, setLoading) => {
    try {
        setLoading(true)
        let courtInfo = getCourtInfo ?  JSON.parse(getCourtInfo) : null
        let { data, error } = await addScheduleCart({
            ...form,
            place: courtInfo._id
        }, courtInfo._id);
        if ( error ) { throw error }
        if ( data ) { 
            setBooking(data)
            setLoading(false)
            deleteLocalBookingForm()
        }
    } catch (error) {
        setLoading(false);
        console.log(error)
    }
}

export const deleteCart = async(cartId, cartInfo, setBooking, setLoading) => {
    try {
        setLoading(true)
        let courtInfo = getCourtInfo ?  JSON.parse(getCourtInfo) : null
        let { data, error } = await deleteScheduleCart(cartId ,courtInfo._id, cartInfo.isExpired);
        if ( error ) { throw error }
        if ( data ) { 
            setBooking(data)
            setLoading(false)
        }
        setLoading(false)
    } catch (error) {
        setLoading(false)
        console.log(error)
    }
}

export const loadCouponPopup = async(setCouponAvailable) => {
    try {
        let { data, error } = await getCouponPopup()
        if ( error ) { throw error }
        if ( data)  { setCouponAvailable(data) }
    } catch (error) {
        console.log(error)
    }
}

export const checkCouponAvaibility = async(promoCode, totalSession, setCoupon, subTotal, setLoading) => {
    try {
        console.log(promoCode)
        // setLoading(true)
        let { data, error } = await checkCoupon(promoCode, totalSession, subTotal);
        if ( error ) { throw error; }
        if ( data)  { setCoupon(data); }
        // setLoading(false); 
    } catch (error) {
        // setLoading(false)
        Confirm("", error)
    }
}

export const checkCouponAvaibilityReturn = async(promoCode, totalSession, subTotal, setLoading) => {
    try {
        // setLoading(true)
        let { data, error } = await checkCoupon(promoCode, totalSession, subTotal);
        if ( error ) { throw error; }
        if ( data)  { return { data }; }
        // setLoading(false); 
    } catch (error) {
        // setLoading(false)
        return { error }
    }
}

export const checkUserisLoggedIn = () => {
    try {
        // check apakah sudah login ?

        const currentUserString = getUserInfo;
        if (!currentUserString) {
            return false
        }

        return true

    } catch (error) {
        return false
    }
}

export const paymentLogic = async (form, showAlert, showLogin, codePromo, selectValuePack, setLoading, navigate) => {
    try {
        // check apakah sudah login ?
        setLoading(true)

        const currentUserString = getUserInfo;
        if (!currentUserString) {
            setLoading(false)
            showLogin(true)
        }

        let courtInfo = getCourtInfo ?  JSON.parse(getCourtInfo) : null
        let { data, error } = await submitOrder({
            codePromo,
            selectValuePack,
            placeId: courtInfo._id
        })

        if (error) { throw error }
        if (data) { 
            setLoading(false)
            navigate(`/payment/${data._id}/booking?page=cart`)
        }

    } catch (error) {
        console.log(error)
        setLoading(false)
        Confirm("", error)
    }
}

export const paymentLogicReturnData = async (form, showAlert, showLogin, codePromo, selectValuePack, setLoading) => {
    try {
        // check apakah sudah login ?
        setLoading(true)

        const currentUserString = getUserInfo;
        if (!currentUserString) {
            setLoading(false)
            showLogin(true)
        }

        let courtInfo = getCourtInfo ?  JSON.parse(getCourtInfo) : null
        let { data, error } = await submitOrder({
            codePromo,
            selectValuePack,
            placeId: courtInfo._id
        })

        if (error) { throw error }
        if (data) { 
            setLoading(false)
            return data
        }

    } catch (error) {
        console.log(error)
        setLoading(false)
        Confirm("", error)
    }
}

export const saveLocalBookingForm = (data) => {
    try {
        setLocalBookingForm({
            ...data,
            date: moment(data.date).utcOffset(7).format("YYYY-MM-DD")
        })
    } catch (error) {
        console.log(error)
    }
}

export const usePackageinCart = async (packageSession) => {
    try {
        let courtInfo = getCourtInfo ?  JSON.parse(getCourtInfo) : null
        // setLocalSessionUsage(packageSession)
        let { data, error } = await setValuePack({
            placeId: courtInfo._id,
            sessionsUsage: packageSession
        })
        return { data }
    } catch (error) {
        return { error }
    }
}

export const loadUserCart = async () => {
    try {
        let courtInfo = getCourtInfo ?  JSON.parse(getCourtInfo) : null
        // setLocalSessionUsage(packageSession)
        let { data, error } = await getUserCartSummary(courtInfo._id)
        if (error) { throw error }
        return { data }
    } catch (error) {
        return { error }
    }
}

export const cancelValuePackUse = async (
    setPackUsageInfo, setSelectValuePack, setLoading
) => {
    try {
        // setLoading(true)
        let { data, error } = await usePackageinCart({
            allSession: {
                indoor: 0,
                outdoor: 0,
                hittingRoom: 0
            },
            outdoorSessions: 0,
            hittingRoomSessions: 0
        })
        if (error) { Confirm("", "Try again") }
        if (data) {
            setPackUsageInfo({
                totalBefore: data.totalBefore,
                totalOrder: data.totalOrder,
                selectedSession: data.totalSessionRedeemed,
                totalSession: data.totalSessions
            })
            setSelectValuePack(false)
        }
        // setLoading(false)
        return { data }
    } catch (error) {
        // setLoading(false)
        return { error }
    }
}

export const loadLocalBookingForm = getLocalBookingForm
export const removeLocalBookingForm = () => { deleteLocalBookingForm() }