import React, { useEffect, useState } from "react";
import { Box, Card, Checkbox, FormControlLabel, Grid } from "@mui/material";
import { TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import DialogHeader from "components/SlideDialog/DialogHeader";
import MKButton from "components/MKButton";
import { orderStatusMap } from "helper/constant";
import { color } from "helper/constant";
import ProductListHeader from "./ProductListHeader";
import { confirmRent } from "service/counterService";
import SlideAlert from "components/SlideInAlert/SlideAlert";


function RentConfirmation ({
    data,
    closeDialog
}) {

    const [ barcode, setBarcode ] = useState("")
    const [ rentCheckId, setRentCheckId ] = useState([])
    const [ returnCheckId, setReturnCheckId ] = useState([])
    const [ loading, setLoading ] = useState(false)

    useEffect(() => {
        if (data && (data.info && data.status == "alert")) {
            const closeAlert = setTimeout(() => {
                closeDialog()
            }, 2000)
            return () => clearTimeout(closeAlert)
        }
    }, [data])

    useEffect(() => {
        console.log('Hello', returnCheckId, rentCheckId)
    }, [returnCheckId, rentCheckId])

    // function to check uncheck
    const checkUncheck = (_id, array, setCheckId) => {
        // check if _id exist then _id splice, else push
        console.log('here check _id', _id)
        let newCheckId = [...array]
        let index = newCheckId.indexOf(_id)
        if (index >= 0) {
            // newCheckId = newCheckId.splice(index, 1)
            // console.log('here check index', index, newCheckId)
            newCheckId.splice(index, 1)
            setCheckId(newCheckId)
        } else {
            newCheckId.push(_id)
            setCheckId(newCheckId)
        }
    }

    const confirmRentButton = async () => {
        setLoading(true)
        let confirmRentService = await confirmRent({
            ids: rentCheckId,
            staff: data.staff
        })
        setLoading(false)
        if (confirmRentService.error) { console.log(confirmRentService.error); closeDialog('QR not valid') }
        if (confirmRentService.data) { closeDialog('Rent start') }
        // confirmRent
    }

    const confirmReturnButton = async () => {
        setLoading(true)
        let confirmReturnService = await confirmRent({
            ids: returnCheckId,
            staff: data.staff,
            returnRent: true
        })
        setLoading(false)
        if (confirmReturnService.error) { console.log(confirmReturnService.error); closeDialog('QR not valid') }
        if (confirmReturnService.data) { closeDialog('Rent completed') }
        // confirmRent
    }

    return (
        <Grid textAlign={"center"} pb={3}>
            <DialogHeader closeDialog={() => { closeDialog() }}/>
            {
                data ? data.info ? <TCTitleLabel>{data.info}</TCTitleLabel> :
                <Grid>
                    {
                        (data.rentList && data.rentList.length > 0) && 
                        <Grid item container xs>
                            <ProductListHeader order={data.order} productList={data.rentList}
                                setCheckId={setRentCheckId}
                                checkId={rentCheckId}
                                checkUncheck={checkUncheck}
                            />
                            <Grid mt={4} xs={12} textAlign={"center"}>
                                <MKButton color="primary" disabled={rentCheckId.length == 0 || loading}
                                    onClick={() => {
                                        console.log("Hello world")
                                        confirmRentButton()
                                    }
                                }>Rent</MKButton>
                            </Grid>
                        </Grid>
                    }
                    {
                        (data.returnList && data.returnList.length > 0) &&
                        <Grid item container xs>
                            <ProductListHeader order={data.order} productList={data.returnList}
                                setCheckId={setReturnCheckId}
                                checkId={returnCheckId}
                                checkUncheck={checkUncheck}
                            />
                            <Grid mt={4} xs={12} textAlign={"center"}>
                                <MKButton color="primary" disabled={returnCheckId.length == 0 || loading}
                                    onClick={() => {
                                        console.log("Hello world")
                                        confirmReturnButton()
                                    }}
                                >Return</MKButton>
                            </Grid>
                        </Grid>
                    }
                </Grid>
                :
                <TCTitleLabel>Scan Staff Barcode</TCTitleLabel>
            }
            {/* <SlideAlert>
                <TCLabel>Order Complete</TCLabel>
            </SlideAlert> */}
        </Grid>
    )
}


export default RentConfirmation