import React, { useEffect, useState } from "react"
import { Card, Grid } from "@mui/material"
import { getRentOrder } from "service/counterService"
import moment from "moment"
import SlideAlert from "components/SlideInAlert/SlideAlert"
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import MKButton from "components/MKButton"
import RentConfirmation from "./component/RentConfirmation"
import { TCTitleLabel } from "components/TopCourtComponents/Label/TopCourtLabel"
import { timeStringFormat } from "helper/constant"
import { color } from "helper/constant"
import { courtTypeObj } from "helper/constant"
import Loading from "components/TopCourtComponents/Loading/TCLoadingComponent"
import { sortInformation } from "helper/productSort"

function CounterRentTable ({
    rerender,
    setOpenRentAlert
}) { 

    const [ rentData, setRentData ] = useState([])
    const [ loading, setLoading ] = useState(true)

    useEffect(() => {
        loadRentOrder()
    }, [rerender])

    useEffect(() => {
        console.log(rentData)
    }, [rentData])

    const loadRentOrder = async () => {
        // load the order is not yet rent
        // rent flow is consist of paid -> rent -> complete
        let date = moment(new Date()).format('YYYY-MM-DD')
        let { data, error } = await getRentOrder(date)
        setLoading(false)
        if (error) { throw error }
        if (data) {
            console.log('here data info', data)
            setRentData(data)
        }
    }

    // check if rent or late
    const checkRent = (value) => {
        let data = {
            rent: false,
            late : false,
            return: false
        }
        console.log('here check value', value)
        if (value.rentStatus[value.rentStatus.length - 1].status == "complete") { data.return = true; return data }
        if (moment(value.date) < moment(new Date())) {
            if (moment(value.date).format("YYYY-MM-DD") === moment(new Date()).format("YYYY-MM-DD")) {
                let endHoursFormat = value.hours[value.hours.length - 1].split(':')[0]
                endHoursFormat = Number(endHoursFormat) + 1
                endHoursFormat = endHoursFormat > 9 ? `${endHoursFormat}:00` : `0${endHoursFormat}:00`
                let ordersFinish = new Date(`${value.date} ${endHoursFormat}`)
                console.log('here orderFinish', ordersFinish)
                if (moment(ordersFinish) < moment(new Date())) {
                    data.late = true
                    return data
                } else {
                    data.rent = true
                    return data
                }
            }
            console.log('here orderFinish', moment(value.date), moment(new Date()))
            data.late = true
            return data
        } else {
            let endHoursFormat = value.hours[value.hours.length - 1].split(':')[0]
            endHoursFormat = Number(endHoursFormat) + 1
            endHoursFormat = endHoursFormat > 9 ? `${endHoursFormat}:00` : `0${endHoursFormat}:00`
            let ordersFinish = new Date(`${value.date} ${endHoursFormat}`)
            console.log('here orderFinish', ordersFinish)
            if (moment(ordersFinish) < moment(new Date())) {
                data.late = true
                return data
            }
        }
        data.rent = true
        return data
    }

    return (
        <div style={{
            paddingTop: 24
        }}>
            {
                loading &&
                <Loading/>
            }
            {
                (rentData.length == 0 && !loading) &&
                <Grid container alignItems={"center"} textAlign={"center"}>
                    <Grid item xs={12} mt={12}>
                        <TCTitleLabel>No Data</TCTitleLabel>
                    </Grid>
                </Grid>
            }
            {/* Product name and image, Booking Id, Button to scan */}
            {
                rentData.map( value => {
                    console.log('here value info', value)
                    return (
                        <Grid container alignItems={"center"} sx={{
                            borderBottom: '1px dotted grey',
                            background: ''
                        }} onClick={() => {
                            if (value.rentStatus && value.rentStatus.length == 0) { } else {
                                if (value.status != 'complete') { setOpenRentAlert(value._id) }
                            }
                        }}>
                            {
                                (value.diffHours || value.diffCourt) ?
                                <Grid item container xs={9.1} p={1} sx={{
                                    borderRight: '1px dotted grey'
                                }}>
                                    <Grid item><TCLabel style={{
                                        fontWeight: 'bold'
                                    }}>{value.user.name}</TCLabel></Grid>
                                    {
                                        sortInformation(value.orderItem).map(vI => {
                                        // value.orderItem.map(vI => {
                                            return (
                                                <Grid container pt={1} xs={12}>
                                                    <Grid item>
                                                        <TCLabel style={{
                                                            fontWeight: 'bold'
                                                        }}>{`${courtTypeObj[vI.court.courtType].toUpperCase()} ${vI.court.name.toUpperCase()} |`}</TCLabel>
                                                    </Grid>
                                                    <Grid item ml={0.5}>
                                                        <TCLabel style={{
                                                            fontWeight: 'bold'
                                                        }}>{`${moment(vI.date).format('DD/MM/YYYY')} |`}</TCLabel>
                                                    </Grid>
                                                    {/* map hours */}
                                                    <Grid item container xs={6} ml={.5}>
                                                    {
                                                        vI.hours.map((h, index) => {
                                                            return <Grid item>
                                                                <TCLabel style={{fontWeight: 'bold'}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)}`}{index < (vI.hours.length - 1) ? <span style={{padding: 3, fontFamily: 'roboto', fontWeight: 50}}>{"|"}</span> : ""}
                                                                {/* ${index < (v.hours.length - 1) ? " | " : ""} */}
                                                                </TCLabel>
                                                            </Grid>
                                                        })
                                                    }
                                                    </Grid>
                                                    <Grid item container spacing={1}>
                                                        <Grid item xs={3}>
                                                            <Card>
                                                                <Grid item container pr={2} alignItems={"center"}>
                                                                    <Grid item xs={6} textAlign={"center"}>
                                                                        <img src={vI.image} width={'100%'} 
                                                                            style={{
                                                                                objectFit: 'contain',
                                                                                borderTopLeftRadius: 8, borderTopRightRadius: 8, minHeight: 60, maxHeight: 80,
                                                                                minWidth: 60, maxWidth: 60
                                                                            }}/>
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <Grid xs={12}><TCLabel bold>{vI.category}</TCLabel></Grid>
                                                                        <Grid xs={12}><TCLabel>{vI.name}</TCLabel></Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Card>
                                                        </Grid>
                                                    {/* {
                                                        value.orderItem.map(v => {
                                                            return (
                                                                <Grid item xs={3}>
                                                                    <Card>
                                                                        <Grid item container pr={2} alignItems={"center"}>
                                                                            <Grid item xs={6} textAlign={"center"}>
                                                                                <img src={v.image} width={'100%'} 
                                                                                    style={{
                                                                                        objectFit: 'contain',
                                                                                        borderTopLeftRadius: 8, borderTopRightRadius: 8, minHeight: 60, maxHeight: 80,
                                                                                        minWidth: 60, maxWidth: 60
                                                                                    }}/>
                                                                            </Grid>
                                                                            <Grid item xs={6}>
                                                                                <Grid xs={12}><TCLabel bold>{v.category}</TCLabel></Grid>
                                                                                <Grid xs={12}><TCLabel>{v.name}</TCLabel></Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Card>
                                                                </Grid>
                                                            )
                                                        })
                                                    } */}
                                                    </Grid>
                                                </Grid>
                                            )
                                        })
                                    }
                                    {/* <Grid item>
                                        <TCLabel style={{
                                            fontWeight: 'bold'
                                        }}>{value.bookingId}</TCLabel>
                                    </Grid> */}
                                </Grid>
                                :
                                <Grid item container xs={9.1} p={1} sx={{
                                    borderRight: '1px dotted grey'
                                }}>
                                    <Grid item><TCLabel style={{
                                        fontWeight: 'bold'
                                    }}>{value.user.name}</TCLabel></Grid>
                                    <Grid container xs={12}>
                                        <Grid item>
                                            <TCLabel style={{
                                                fontWeight: 'bold'
                                            }}>{`${courtTypeObj[value.court.courtType].toUpperCase()} ${value.court.name.toUpperCase()} |`}</TCLabel>
                                        </Grid>
                                        <Grid item ml={0.5}>
                                            <TCLabel style={{
                                                fontWeight: 'bold'
                                            }}>{`${moment(value.date).format('DD/MM/YYYY')} |`}</TCLabel>
                                        </Grid>
                                        {/* map hours */}
                                        <Grid item container xs={6} ml={.5}>
                                        {
                                            value.hours.map((h, index) => {
                                                return <Grid item>
                                                    <TCLabel style={{fontWeight: 'bold'}}>{`${h} - ${timeStringFormat(Number(h.split(':')[0]) + 1)}`}{index < (value.hours.length - 1) ? <span style={{padding: 3, fontFamily: 'roboto', fontWeight: 50}}>{"|"}</span> : ""}
                                                    {/* ${index < (v.hours.length - 1) ? " | " : ""} */}
                                                    </TCLabel>
                                                </Grid>
                                            })
                                        }
                                        </Grid>
                                    </Grid>
                                    {/* <Grid item>
                                        <TCLabel style={{
                                            fontWeight: 'bold'
                                        }}>{value.bookingId}</TCLabel>
                                    </Grid> */}
                                    <Grid item container spacing={1}>
                                    {
                                        value.orderItem.map(v => {
                                            return (
                                                <Grid item xs={3}>
                                                    <Card>
                                                        <Grid item container pr={2} alignItems={"center"}>
                                                            <Grid item xs={6} textAlign={"center"}>
                                                                <img src={v.image} width={'100%'} 
                                                                    style={{
                                                                        objectFit: 'contain',
                                                                        borderTopLeftRadius: 8, borderTopRightRadius: 8, minHeight: 60, maxHeight: 80,
                                                                        minWidth: 60, maxWidth: 60
                                                                    }}/>
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <Grid xs={12}><TCLabel bold>{v.category}</TCLabel></Grid>
                                                                <Grid xs={12}><TCLabel>{v.name}</TCLabel></Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                </Grid>
                                            )
                                        })
                                    }
                                    </Grid>
                                </Grid>
                            }
                            <Grid item xs={2.5} p={1} textAlign={"left"}>
                                {
                                    !value.rentStatus || (value.rentStatus && value.rentStatus.length == 0) ?
                                    <Grid item container p={2} justifyContent={"left"}>
                                        <Grid item>
                                            <TCLabel>{value.lastStatus == "complete" ? "Completed" : "Rent"}</TCLabel>
                                        </Grid>
                                    </Grid>
                                    :
                                    <Grid item container justifyContent={"center"}>
                                        <Grid item xs={12}>
                                            <div className='custom-body' style={{
                                                padding: '1px 5px',
                                                borderRadius: 5,
                                                // color: paymentStatus ? color.darkgreen : data.lastStatus != "failed" ? color.darkorange : 'white',
                                                color: 'white',
                                                fontSize: 10,
                                                fontWeight: 'bold',
                                                width: 'fit-content',
                                                backgroundColor: checkRent(value).return ? color.green : checkRent(value).late ? '#FF0000' : checkRent(value).rent ? color.orange : color.pink}}>
                                                { checkRent(value).return ? "Return" : "Rent Out"}
                                                {/* {paymentStatus ? "Paid" : data.lastStatus != "failed" ? "Waiting Payment" : orderStatus.failed} */}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} textAlign={"left"}>
                                            <TCLabel>Rent-out {moment(value.rentStatus[0].date).format("DD-MM-YYYY HH:mm:ss")} by {value.rentStatus[0].admin.name}</TCLabel>
                                        </Grid>
                                        {
                                            value.rentStatus.length > 1 &&
                                            <Grid item xs={12} mt={1} textAlign={"left"}>
                                                <TCLabel>Return {moment(value.rentStatus[value.rentStatus.length - 1].date).format("DD-MM-YYYY HH:mm:ss")} by {value.rentStatus[value.rentStatus.length - 1].admin.name}</TCLabel>
                                            </Grid>
                                        }
                                    </Grid>
                                }
                            </Grid>
                        </Grid>
                    )
                })
            }
        </div>
    )
}

export default CounterRentTable