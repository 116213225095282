import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { AppBar, Card, Container, Grid, Icon, Tab, Tabs, Box, Button } from "@mui/material";
import MKTypography from "components/MKTypography";
import { getUserInfo } from "helper/localStorage";

import { getMe } from "service/authService";

import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { setUserInfo, deleteUserInfo } from "helper/localStorage";
import TCNavigationBar from "components/TopCourtComponents/NavigationBar/TopCourtNavigationBar";
import CounterBuy from "./CounterBuy";
import CounterRent from "./CounterRent";

function CustomTabPanel(props) {
    const { children, value, index, boxPadding = 3, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: boxPadding }}>
            <MKTypography>{children}</MKTypography>
          </Box>
        )}
      </div>
    );
}

function CounterRentorBuy ({
    counter = false
}) {

    const navigate = useNavigate();
    const [ showAlert, setShowAlert ] = useState(false)
    const [ activeTab, setActiveTab ] = useState(0)
    const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null

    const [ userDetail, setUserDetail ] = useState(userInfo)

    useEffect(() => {
        loadMe()
        if (!userInfo) {
            setShowAlert(true)
        }
    }, [])

    const loadMe = async () => {
        let { data, error } = await getMe()
        if (error) { console.log(error) }
        if (data) {  setUserDetail(data); setUserInfo(data) }
    }

    return (
        <>
            <AppBar position="static">
                <Tabs value={activeTab} sx={{ m: 2, mb: 0, mt: 0 }} 
                    onChange={(event, newValue) => {
                    setActiveTab(newValue)
                }}>
                    <Tab label={<TCLabel>Rent</TCLabel>} />
                    <Tab label={<TCLabel>Buy</TCLabel>} />
                </Tabs>
                <CustomTabPanel boxPadding={1} value={activeTab} index={0}>
                    <div style={{overflow: 'auto', height: '78vh', marginLeft: 8, marginRight: 8}}>
                        <CounterRent counter={counter}/>
                    </div>
                </CustomTabPanel>
                <CustomTabPanel boxPadding={1} value={activeTab} index={1}>
                    <div style={{overflow: 'auto', height: '78vh', marginLeft: 8, marginRight: 8}}>
                        <CounterBuy counter={counter}/>
                    </div>
                </CustomTabPanel>
            </AppBar>
        </>
    )
}

export default CounterRentorBuy;