import React, { useEffect, useRef, useState } from "react";
import { getCourtInfo } from "helper/localStorage";

import { Box, Container, Grid, Icon, Input } from "@mui/material";
import { TCLabel } from "components/TopCourtComponents/Label/TopCourtLabel";
import { toGMT7 } from "helper/time";
import { getCourtsDetail } from "pages/Booking/Function";
import { toLocalGMT } from "helper/time";
import MKButton from "components/MKButton";

import { checkQRService, checkReturnOrder } from "service/counterService";
import { playSound, playSoundError } from "./function/helper/playSound";
import { useNavigate } from "react-router-dom";
// check if counter being set ( login counter )

import gifSTC from "../assets/counter/Gif_STC_HD.gif";
import bgSTC from "../assets/counter/BG_STC_HD.jpg";
import topcourtlink from "../assets/counter/topcourtlink.png";
import instagram from "../assets/counter/instagramlink.png";
import whatsapp from "../assets/counter/whatsapplink.png";

import MKBox from "components/MKBox";

import court from "../assets/logo/court.png";
import calendar from "../assets/counter/calendar.png";
import shoppingCart from "../assets/counter/shopping-cart.png";
import game from "../assets/counter/game.png";
import QRCode from "react-qr-code";
import { getUserInfo } from "helper/localStorage";
import SlideAlert from "components/SlideInAlert/SlideAlert";
import CounterLogin from "./function/view/CounterLogin";

import CounterBooking from "./CounterBooking";
import CounterTimeTable from "./CounterTimeTable";
import { color } from "framer-motion";
import { courtTypeObj } from "helper/constant";

import CounterPickup from "./CounterPickup";
import CounterRentTable from "./CounterRentTable";
import RentConfirmation from "./component/RentConfirmation";
import PickupConfirmation from "./component/PickupConfirmation";
import DialogHeader from "components/SlideDialog/DialogHeader";
import { checkOrderBarcode } from "service/counterService";

import { saveOpenedMenu, loadOpenedMenu, deleteOpenedMenu } from "helper/localStorage";

let port = null

function Counter ({
    
}) {

    const courtInfoJSON = getCourtInfo ? JSON.parse(getCourtInfo) : null
    const userInfo = getUserInfo ? JSON.parse(getUserInfo) : null
    const navigate = useNavigate()
    
    // const [ showLogin, setShowLogin ] = useState(userInfo ? false : true)
    const [ showLogin, setShowLogin ] = useState(false)

    const [ form, setForm ] = useState({
        selectedDate: toLocalGMT(new Date()),
        date: toGMT7(new Date()),
        month: toGMT7(new Date(), 'MM'),
        year: toGMT7(new Date(), 'YYYY'),
        court: null,
        hours: [],
        totalSessions: 0,
        totalPrice: 0,
    })

    const [ rerender, setRerender ] = useState(Date.now())
    const [ qrCodeValue, setQRCodeValue ] = useState("")
    const [ isStaff, setIsStaff ] = useState(false)

    let currOpenedMenu = loadOpenedMenu
    console.log('here check openedmenu', currOpenedMenu)

    const [ showMenu, setShowMenu ] = useState({
        courtBooking: false,
        // schedule: (currOpenedMenu && JSON.parse(currOpenedMenu) == "schedule") ? true : false,
        schedule: false,
        rent: (currOpenedMenu && JSON.parse(currOpenedMenu) == "rent") ? true : false,
        buy: (currOpenedMenu && JSON.parse(currOpenedMenu) == "buy") ? true : false
    })

    const [ showAlert, setShowAlert ] = useState({
        open: false,
        info: ""
    })

    const [ openBuyRentAlert, setOpenBuyRentAlert ] = useState({
        open: false,
        type: "",
        data: null,
        orderInfo: null
    })

    const [ staffId, setStaffId ] = useState("")

    const [ returnId, setReturnOrderId ] = useState("")
    const [ viewNextDay, setViewNextDay ] = useState(false)

    useEffect(() => { console.log('here bookingData', rerender) }, [rerender])

    useEffect(() => {
        if ("serial" in navigator) {
            getSerialPorts()
        }
    }, [])

    useEffect(() => {
        const getData = setTimeout(() => {
            if (qrCodeValue ) { 
                if (isStaff) {
                    loadOrderDetail()
                } else if (returnId && returnId !== "") {
                    checkStaffBarcode()
                } else { checkQR() } 
            }
        }, 800)
    
        return () => clearTimeout(getData)
    }, [qrCodeValue])

    useEffect(() => {
        const closeAlert = setTimeout(() => {
            if (showAlert.open == true)  {
                setShowAlert({
                    open: false,
                    info: ""
                })
                navigate(0)
            }
        }, 7000)
    
        return () => clearTimeout(closeAlert)
    }, [showAlert.open])

    const getSerialPorts = async() => {
        const ports = await navigator.serial.getPorts()
        if (ports.length == 0) {
        } else {
            port = ports[0]
            await port.open({
                baudRate: 9600
            })
        }
    }

    const checkStaffBarcode = async() => {
        try {
            let { data, error } = await checkReturnOrder({
                uniqueCode: qrCodeValue,
                orderId: returnId
            })
            
            if (error) { throw error }

            if (data) {
                if (data.rentList.length > 0 || data.returnList.length > 0) { 
                    setOpenBuyRentAlert({
                        open: true,
                        type: "rent",
                        data: {
                            staff: data.staff._id,
                            order: data.order,
                            rentList: data.rentList,
                            returnList: data.returnList
                        },
                    })
                    setReturnOrderId("")
                }
            }

        } catch (error) {
            setQRCodeValue(""); 
            setOpenBuyRentAlert({
                open: false,
                type: "",
                data: null,
                orderInfo: null
            })
            setShowAlert({
                open: true,
                info: <TCLabel style={{fontSize: 26}}>{"QR not valid"}</TCLabel>
            }); 
            playSoundError(null, navigate);
        }
    }

    const loadOrderDetail = async () => {
        try {
            let { data, error } = await checkOrderBarcode({
                uniqueCode: qrCodeValue
            })
            if (error) { setQRCodeValue(""); 
                setOpenBuyRentAlert({
                    open: false,
                    type: "",
                    data: null,
                    orderInfo: null
                })
                setShowAlert({
                    open: true,
                    info: <TCLabel style={{fontSize: 26}}>{"QR not valid"}</TCLabel>
                }); 
                playSoundError(data, navigate);
            }
            if (data) { 
                setQRCodeValue(""); 
                if (data.orderList.length > 0) { 
                    setOpenBuyRentAlert({
                        open: true,
                        type: "sell",
                        data: {
                            staff: staffId,
                            order: data.order,
                            orderList: data.orderList,
                        },
                    })
                }
                if (data.rentList.length > 0 || data.returnList.length > 0) { 
                    setOpenBuyRentAlert({
                        open: true,
                        type: "rent",
                        data: {
                            staff: staffId,
                            order: data.order,
                            rentList: data.rentList,
                            returnList: data.returnList
                        },
                    })
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const loadSerialPorts = async() => {
        if ("serial" in navigator) { 
            console.log("serial supported")
            port = await navigator.serial.requestPort()
            await port.open({
                baudRate: 9600
            })
        }
    }

    const checkQR = async () => {
        let { data, error } = await checkQRService({
            uniqueCode: qrCodeValue
        })
        if (error) { setQRCodeValue(""); setShowAlert({
            open: true,
            info: <TCLabel style={{fontSize: 26}}>{"QR not valid"}</TCLabel>
        }); 
            playSoundError(data, navigate);
        }
        if (data) { 
            console.log('check Data', data)
            if (data.isStaffBarcode) {
                setOpenBuyRentAlert({
                    open: true,
                    type: "staff",
                    data: null,
                    orderInfo: null
                })
                setQRCodeValue("")
                setIsStaff(true)
                setStaffId(data.staff._id)
                return
            }
            if (data.specialAccess) {
                const textEncoder = new TextEncoderStream()
                if (port) {
                    textEncoder.readable.pipeTo(port.writable)
                    const writer = textEncoder.writable.getWriter()
                    await writer.write('K')
                }
                setQRCodeValue("")
                navigate(0)
                return;
            }
            setShowAlert({
                open: true,
                info: <Grid textAlign={"center"} justifyContent={"center"}>
                    <Grid container spacing={0.5}>
                        <Grid item>
                            <TCLabel style={{fontSize: 26}}>{`Hi `}</TCLabel>
                        </Grid>
                        <Grid item>
                            <TCLabel style={{fontSize: 26}} bold>{`${data.order ? data.order.user.name.toUpperCase() : ''},`}</TCLabel>
                        </Grid>
                        <Grid item>
                            <TCLabel style={{fontSize: 26}}>{`Welcome to STC!`}</TCLabel>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0.5}>
                        <Grid item>
                            <TCLabel style={{fontSize: 26}}>{`Your session time is `}</TCLabel>
                        </Grid>
                        <Grid item>
                            <TCLabel style={{fontSize: 26}} bold>{`${data.hours[0]} - ${(Number(data.hours[data.hours.length - 1].split(":")[0]) + 1) > 9 ? `${Number(data.hours[data.hours.length - 1].split(":")[0]) + 1}` : `0${Number(data.hours[data.hours.length - 1].split(":")[0]) + 1}`}:00`}</TCLabel>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0.5}>
                        <Grid item>
                            <TCLabel style={{fontSize: 26}}>{`Please proceed to`}</TCLabel>
                        </Grid>
                        <Grid item>
                            <TCLabel style={{fontSize: 26}} bold>{courtTypeObj[data.court.courtType].toUpperCase()} {data.court.name.toUpperCase()}</TCLabel>
                        </Grid>
                    </Grid>
                </Grid>
            })
            // playSoundRef.current.click()
            playSound(data, navigate)
            const textEncoder = new TextEncoderStream()
            if (port) {
                textEncoder.readable.pipeTo(port.writable)
                const writer = textEncoder.writable.getWriter()
                await writer.write('K')
            }
            setQRCodeValue("")
            // navigate(0)
        }
    }

    return(
        <Grid sx={{position: 'fixed', left: 0, right: 0, bottom: 0, top: 0,
            backgroundImage: `url(${(showMenu.courtBooking || showMenu.schedule || showMenu.rent || showMenu.buy) ? bgSTC : gifSTC})`,
            backgroundSize: "contain",
            backgroundPosition: "top",
            display: "grid",
            placeItems: "center",
        }} onClick={(e) => {
            e.stopPropagation()
            if (!openBuyRentAlert.open) {
                setShowMenu({
                    courtBooking: false,
                    schedule: false,
                    rent: false,
                    buy: false
                })
                setViewNextDay(false)
                deleteOpenedMenu()
            }
        }}>
            <Grid container xs={12} p={2} spacing={2} alignItems={"center"} justifyContent={"center"} textAlign={"center"}>
                <Grid xs={3} onClick={(e) => {
                    e.stopPropagation()
                    setShowMenu({
                        courtBooking: false,
                        schedule: true,
                        rent: false,
                        buy: false
                    })
                    saveOpenedMenu('schedule')
                }}>
                    <MKButton style={{
                        minHeight: 100,
                        minWidth: 200
                    }}>
                        <Grid>
                            <Grid xs={12}>
                                <img src={calendar} width={80} height={80} />                    
                            </Grid>
                        </Grid>
                    </MKButton>
                    <TCLabel>Today Schedule</TCLabel>
                </Grid>
                <Grid xs={3} onClick={(e) => {
                    e.stopPropagation()
                    setShowMenu({
                        courtBooking: false,
                        schedule: false,
                        rent: true,
                        buy: false
                    })
                    saveOpenedMenu('rent')
                }}>
                    <MKButton style={{
                        minHeight: 100,
                        minWidth: 200
                    }}>
                        <Grid>
                            <Grid xs={12}>
                                <img src={game} width={80} height={80} />                    
                            </Grid>
                        </Grid>
                    </MKButton>
                    <TCLabel>Rent</TCLabel>
                </Grid>
                <Grid xs={3} onClick={(e) => {
                    e.stopPropagation()
                    setShowMenu({
                        courtBooking: false,
                        schedule: false,
                        rent: false,
                        buy: true
                    })
                    saveOpenedMenu('buy')
                }}>
                    <MKButton style={{
                        minHeight: 100,
                        minWidth: 200
                    }}>
                        <Grid>
                            <Grid xs={12}>
                                <img src={shoppingCart} width={80} height={80} />                    
                            </Grid>
                        </Grid>
                    </MKButton>
                    <TCLabel>Buy</TCLabel>
                </Grid>
                {/* <Grid xs={4}>
                    <MKButton>
                        <image src={court} width={30} height={30} />
                        <TCLabel>Book Court</TCLabel>
                    </MKButton>
                </Grid> */}
            </Grid>
            {
                (showMenu.schedule || showMenu.courtBooking || showMenu.rent || showMenu.buy) &&
                <Grid style={{
                    position: 'fixed',
                    top: '30%',
                    width: '80%'
                }}>
                    {
                        showMenu.courtBooking &&
                        <Grid>
                            <CounterBooking/>
                        </Grid>
                    }
                    {
                        showMenu.schedule &&
                        <div>
                            <Grid container xs sx={{position: 'fixed', bottom: 8, right: '2vw', justifyContent:"right" }}>
                                {
                                    !viewNextDay &&
                                    <div style={{
                                        backgroundColor: "#0198E1 !important",
                                        // color: 'white',
                                        color: color.blueIndoor_1,
                                        padding: 4,
                                        paddingLeft: 12, paddingRight: 12,
                                        borderRadius: 8
                                    }} onClick={(e) => {
                                        e.stopPropagation()
                                        setViewNextDay(true)
                                    }}>
                                        <TCLabel inheritColor={true} style={{
                                            fontSize: 16
                                        }}><Icon sx={{paddingTop: 0.3, marginBottom: -0.1}}>arrow_forward_ios</Icon></TCLabel>
                                    </div>
                                }
                                {
                                    viewNextDay &&
                                    <div style={{
                                        backgroundColor: "#0198E1 !important",
                                        // color: 'white',
                                        color: color.blueIndoor_1,
                                        padding: 4,
                                        paddingLeft: 12, paddingRight: 12,
                                        borderRadius: 8
                                    }} onClick={(e) => {
                                        e.stopPropagation()
                                        setViewNextDay(false)
                                    }}>
                                        <TCLabel inheritColor={true} style={{
                                            fontSize: 16
                                        }}><Icon sx={{paddingTop: 0.3, marginBottom: -0.1 }}>arrow_back_ios</Icon></TCLabel>
                                    </div>
                                }
                            </Grid>
                            <Grid container sx={{
                                position: 'absolute',
                                zIndex: 100,
                                background: !viewNextDay ? "#025597 !important" : "grey !important",
                            }}>
                                <Grid item xs={0.85} textAlign={"center"} sx={{
                                    border: '1px dotted grey',
                                }}>
                                    <div style={{fontSize: 15, fontWeight: 'bold', color: 'white'}}>SESSION</div>
                                </Grid>
                                <Grid item xs={1.59} textAlign={"center"} sx={{
                                    border: '1px dotted grey'
                                }}>
                                    <div style={{fontSize: 15, fontWeight: 'bold', color: 'white'}}>INDOOR 1</div>
                                </Grid>
                                <Grid item xs={1.59} textAlign={"center"} sx={{
                                    border: '1px dotted grey'
                                }}>
                                    <div style={{fontSize: 15, fontWeight: 'bold', color: 'white'}}>INDOOR 2</div>
                                </Grid>
                                <Grid item xs={1.59} textAlign={"center"} sx={{
                                    border: '1px dotted grey'
                                }}>
                                    <div style={{fontSize: 15, fontWeight: 'bold', color: 'white'}}>INDOOR 3</div>
                                </Grid>
                                <Grid item xs={1.59} textAlign={"center"} sx={{
                                    border: '1px dotted grey'
                                }}>
                                    <div style={{fontSize: 15, fontWeight: 'bold', color: 'white'}}>INDOOR 4</div>
                                </Grid>
                                <Grid item xs={1.59} textAlign={"center"} sx={{
                                    border: '1px dotted grey'
                                }}>
                                    <div style={{fontSize: 15, fontWeight: 'bold', color: 'white'}}>OUTDOOR 5</div>
                                </Grid>
                                <Grid item xs={1.59} textAlign={"center"} sx={{
                                    border: '1px dotted grey'
                                }}>
                                    <div style={{fontSize: 15, fontWeight: 'bold', color: 'white'}}>OUTDOOR 6</div>
                                </Grid>
                                <Grid item xs={1.59} textAlign={"center"} sx={{
                                    border: '1px dotted grey'
                                }}>
                                    <div style={{fontSize: 15, fontWeight: 'bold', color: 'white'}}>HITTING 1</div>
                                </Grid>
                            </Grid>
                            <div style={{
                                overflow: 'auto',
                                overflowY: 'auto',
                                height: 600,
                            }}>
                                <Grid xs={12} onClick={(e) => { e.stopPropagation() }}>
                                    <CounterTimeTable nextDay={viewNextDay} openRentMenu={() => {
                                        setShowMenu({
                                            courtBooking: false,
                                            schedule: false,
                                            rent: true,
                                            buy: false
                                        })
                                    }}/>
                                </Grid>
                            </div>
                        </div>
                    }
                    {
                        showMenu.rent &&
                        <div>
                            <Grid container sx={{
                                position: 'absolute',
                                zIndex: 100,
                                background: "#025597 !important",
                            }}>
                                <Grid item xs={9} textAlign={"center"} sx={{
                                    border: '1px dotted grey'
                                }}>
                                    <div style={{fontSize: 15, fontWeight: 'bold', color: 'white'}}>Order</div>
                                </Grid>
                                {/* <Grid item xs={3} textAlign={"center"} sx={{
                                    border: '1px dotted grey'
                                }}>
                                    <div style={{fontSize: 15, fontWeight: 'bold', color: 'white'}}>Status</div>
                                </Grid> */}
                                <Grid item xs={3} textAlign={"center"} sx={{
                                    border: '1px dotted grey'
                                }}>
                                    <div style={{fontSize: 15, fontWeight: 'bold', color: 'white'}}>Status</div>
                                </Grid>
                            </Grid>
                            <div style={{
                                overflow: 'auto',
                                overflowY: 'auto',
                                height: 600,
                                backgroundColor: "white",
                            }}>
                                <Grid xs={12} onClick={(e) => { e.stopPropagation() }}>
                                    <CounterRentTable 
                                    rerender={rerender}
                                    setOpenRentAlert={(e) => {
                                        console.log('hello world', e)
                                        setOpenBuyRentAlert({
                                            open: true,
                                            type: "return-staff",
                                            data: null,
                                            orderInfo: null
                                        })
                                        setReturnOrderId(e)
                                        // setOpenBuyRentAlert({
                                        //     ...e,
                                        //     type: "rent"
                                        // })
                                    }}/>
                                </Grid>
                            </div>
                        </div>
                    }
                    {
                        showMenu.buy &&
                        <div>
                            <Grid container sx={{
                                position: 'absolute',
                                zIndex: 100,
                                background: "#025597 !important",
                            }}>
                                <Grid item xs={9} textAlign={"center"} sx={{
                                    border: '1px dotted grey'
                                }}>
                                    <div style={{fontSize: 15, fontWeight: 'bold', color: 'white'}}>Order</div>
                                </Grid>
                                <Grid item xs={3} textAlign={"center"} sx={{
                                    border: '1px dotted grey'
                                }}>
                                    <div style={{fontSize: 15, fontWeight: 'bold', color: 'white'}}>Status</div>
                                </Grid>
                            </Grid>
                            <div style={{
                                overflow: 'auto',
                                overflowY: 'auto',
                                height: 600,
                                backgroundColor: "white",
                            }}>
                                <Grid xs={12} onClick={(e) => { e.stopPropagation() }}>
                                    <CounterPickup 
                                    rerender={rerender}
                                    setOpenRentAlert={(e) => {
                                        console.log('hello world', e)
                                        // setOpenBuyRentAlert({
                                        //     ...e,
                                        //     type: "sell"
                                        // })
                                    }}/>
                                </Grid>
                            </div>
                        </div>
                    }
                </Grid>
            }
            <Grid container pt={4} style={{
                position: 'fixed',
                top: '30%',
                right: 0,
                backgroundColor: 'white',
                borderRadius: 10,
                minHeight: 100,
                width: 100,
            }} textAlign={"center"}>
                <Grid item xs={12} mb={4}>
                    <img src={topcourtlink} width={80} height={80}/>
                </Grid>
                <Grid item xs={12} mb={4}>
                    <img src={instagram} width={80} height={80} />
                </Grid>
                <Grid item xs={12} mb={4}>
                    <img src={whatsapp} width={80} height={80}/>
                </Grid>
            </Grid>
            <Grid container onClick={() => {
                    loadSerialPorts()
            }} style={{
                position: 'fixed',
                bottom: 0,
                left: 10,
                width: 100
            }} justifyContent={"bottom"}>
                <Grid item justifyContent={"bottom"}>
                    <TCLabel subtitle>{"v 1.1.0"}</TCLabel>
                </Grid>
                <Grid item>
                    <Input
                        style={{
                            color: "transparent",
                        }}
                        value={qrCodeValue}
                        onChange={(e) => {
                            setQRCodeValue(e.currentTarget.value)
                        }}
                        autoFocus={!showLogin} onBlur={({ target }) => {
                            if (!showLogin) {
                                setTimeout(() => target.focus(), 10);
                            }
                        }}
                    />
                </Grid>
            </Grid>
            {
                <SlideAlert
                    isOpen={showLogin || showAlert.open || openBuyRentAlert.open}
                    setIsOpen={() => {
                        if (showAlert.open) {
                            setShowAlert({
                                open: !showAlert.open,
                                info: ""
                            })
                            navigate(0)
                        }
                    }}
                >
                    {
                        showLogin && <CounterLogin />
                    }
                    {
                        showAlert.open && <TCLabel>{showAlert.info}</TCLabel>
                    }
                    {
                        openBuyRentAlert.open && openBuyRentAlert.type == "rent" && <RentConfirmation 
                            data={openBuyRentAlert.data}
                            closeDialog={(e) => {
                            setOpenBuyRentAlert({
                                open: !openBuyRentAlert.open,
                                type: "",
                                data: null,
                                orderInfo: null
                            })
                            setIsStaff(false)
                            setStaffId(false)
                            setQRCodeValue("")
                            setRerender(Date.now())
                            if (e) { 
                                setShowAlert({
                                    open: true,
                                    info: <TCLabel style={{fontSize: 26}}>{e}</TCLabel>
                                }); 
                                if (e === 'QR not valid') {
                                    playSoundError(e, navigate);
                                }
                            }
                        }} />
                    }
                    {
                        openBuyRentAlert.open && openBuyRentAlert.type == "sell" && <PickupConfirmation
                            data={openBuyRentAlert.data}
                            orderInfo={openBuyRentAlert.orderInfo}
                            closeDialog={(e) => {
                            setOpenBuyRentAlert({
                                open: !openBuyRentAlert.open,
                                type: "",
                                data: null,
                                orderInfo: null
                            })
                            setIsStaff(false)
                            setStaffId(false)
                            setQRCodeValue("")
                            setRerender(Date.now())
                            if (e) { 
                                setShowAlert({
                                    open: true,
                                    info: <TCLabel style={{fontSize: 26}}>{e}</TCLabel>
                                }); 
                                if (e === 'QR not valid') {
                                    playSoundError(e, navigate);
                                }
                            }
                        }}/> 

                    }
                    {
                        openBuyRentAlert.open && openBuyRentAlert.type == "return-staff" && <Grid textAlign={"center"} justifyContent={"center"}>
                            <DialogHeader closeDialog={() => {
                                setOpenBuyRentAlert({
                                    open: !openBuyRentAlert.open,
                                    type: "",
                                    data: null,
                                    orderInfo: null
                                })
                                setIsStaff(false)
                                setQRCodeValue("")
                                setRerender(Date.now())
                            }}/>
                            <Grid container spacing={0.5}>
                                <Grid item>
                                    <TCLabel style={{fontSize: 26}}>{`Scan Staff Barcode `}</TCLabel>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                    {
                        openBuyRentAlert.open && openBuyRentAlert.type == "staff" && <Grid textAlign={"center"} justifyContent={"center"}>
                            <DialogHeader closeDialog={() => {
                                setOpenBuyRentAlert({
                                    open: !openBuyRentAlert.open,
                                    type: "",
                                    data: null,
                                    orderInfo: null
                                })
                                setIsStaff(false)
                                setQRCodeValue("")
                                setRerender(Date.now())
                            }}/>
                            <Grid container spacing={0.5}>
                                <Grid item>
                                    <TCLabel style={{fontSize: 26}}>{`Scan Order Barcode `}</TCLabel>
                                </Grid>
                            </Grid>
                        </Grid>
                    }
                </SlideAlert>
            }
        </Grid>
    )
}

export default Counter